import React, { useContext, useMemo } from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

import { StoreContext } from '../../context/store-context';
import { useWindowWidth } from '../../utils/hooks';
import DropdownMenu from './DropdownItem';

const HeaderNav = ({ menu }) => {
	const { openMenu, closeMenu, mobileMenuPrimary } = useContext(StoreContext);
	const width = useWindowWidth();
	const navOptions = mobileMenuPrimary?.content?.items;
	const processedMenu = useMemo(
		() =>
			(menu || []).map((item) => {
				let options =
					navOptions?.find((navItem) => navItem?.title === item.id)
						?.children || [];

				if (item.children?.length > 0) {
					options.push(...item.children);
					options = [...new Set(options)];
				}

				if (options.length > 0) {
					options = options.map((option) => {
						const prefetchedUrl = option?.url?.url.includes(
							'https://joshwoodcolour.com'
						)
							? option?.url?.url.replace(
									'https://joshwoodcolour.com',
									''
							  )
							: option?.url?.url;

						return {
							...option,
							url: {
								...option.url,
								url: prefetchedUrl,
							},
						};
					});
				}

				return { ...item, options };
			}),
		[menu, navOptions]
	);

	return (
		<ul className='site-nav site-nav-v2'>
			{processedMenu.map(
				({ id, url, title, isDropdown, children, options }, index) => {
					const classes = cn('site-nav__item', {
						'site-nav__item--has-dropdown':
							!!children && isDropdown,
					});

					return (
						width >= 1300 && (
							<li
								key={`${id}-${index + 1}`}
								className={classes}
								onMouseEnter={isDropdown ? openMenu : null}
								onMouseLeave={isDropdown ? closeMenu : null}
							>
								<Link
									className={`site-nav__link site-nav-v2__link ${
										isDropdown && 'site-nav-v2__arrow-down'
									}`}
									activeClassName='active'
									to={url}
								>
									<span className='site-nav__text site-nav-v2__text'>
										{title}
									</span>
								</Link>
								{isDropdown && children && (
									<DropdownMenu
										items={options}
										parentId={id}
									/>
								)}
							</li>
						)
					);
				}
			)}
		</ul>
	);
};

export default HeaderNav;
