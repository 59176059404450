import React, { useContext } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import { StoreContext } from '../../../context/store-context';
import ProductItemContainer from './ProductItemContainer';

import './ProductsCustomerLove.scss';

SwiperCore.use([Navigation]);

const ProductsCustomerLove = ({ products, allSellingPlanGroups }) => {
	const { allProducts } = useContext(StoreContext);

	const productsToShow = allProducts
		?.filter((product) => products?.includes(product?.handle))
		?.sort(
			(a, b) => products.indexOf(a.handle) - products.indexOf(b.handle)
		);

	return (
		<div className='products-customer-love'>
			<h2>What our customers are loving</h2>
			<div className='products-customer-love__products'>
				<Swiper
					spaceBetween={10}
					breakpoints={{
						0: {
							slidesPerView: 2,
						},
						640: {
							slidesPerView: 2,
						},
						768: {
							slidesPerView: 3,
						},
						1024: {
							slidesPerView: 4,
						},
					}}
					navigation={{
						prevEl: '.swiper-button-prev-ms',
						nextEl: '.swiper-button-next-ms',
					}}
				>
					{productsToShow?.map((product) => (
						<SwiperSlide key={product?.id}>
							<ProductItemContainer
								product={product}
								allSellingPlanGroups={allSellingPlanGroups}
							/>
						</SwiperSlide>
					))}
				</Swiper>

				<div id='custom-prev' className='swiper-button-prev-ms' />
				<div id='custom-next' className='swiper-button-next-ms' />
			</div>
		</div>
	);
};

export default ProductsCustomerLove;
