// External
import React, { useContext, useEffect, useState, lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { v4 as uuid } from 'uuid';
import { storyblokInit } from 'gatsby-source-storyblok';
import axios from 'axios';

// Internal
import Password from '../components/Password';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { customerQuery } from '../api/queries/customer';

import { captureAttributionData, useWindowWidth } from '../utils/hooks';
import { customerFetch, isBrowser } from '../utils/helpers';
import { StoreContext } from '../context/store-context';
import storyblokComponents from '../utils/storyblokComponents';
import useCustomer from '../utils/useCustomer';
import { submitErrorToSlack } from '../utils/slackMessage';
import packageInfo from '../../package.json';
import useCheckStorageVersion from '../helpers/useCheckStorageVersion';
import Overlay from '../components/Overlay';
import Cart from '../components/Cart';
import Loader from '../components/Loader';
import ErrorPage from '../components/ErrorPage';
import useLoadKlaviyoScript from '../hooks/useLoadKlaviyoScript';

const ShotModalContainer = lazy(() => import('../components/ShotModal'));
const Modal = lazy(() => import('../components/Modal'));
const Notification = lazy(() => import('../components/Notification'));

const Layout = ({ children, location }) => {
	const version = packageInfo.version;
	const footerExcludedPaths = ['/pages/thank-you', '/miracle-steps'];
	const shouldHideFooter = footerExcludedPaths.some((path) =>
		location?.pathname?.includes(path)
	);

	const [isAuth, setIsAuth] = useState(true);
	const [password, setPassword] = useState('');
	const [news, setNews] = useState(null);

	useLoadKlaviyoScript(isBrowser);
	useCheckStorageVersion(version);
	captureAttributionData();

	storyblokInit({
		components: storyblokComponents,
	});

	const { customerData } = useCustomer();
	const width = useWindowWidth();
	const {
		cart,
		allProducts,
		customerData: customerDataContext,
		setCustomerData,
		setGlobalMetafields
	} = useContext(StoreContext);

	useEffect(() => {
		const setMetafields = async () => {
			try {
				const { data } = await axios.get('/api/getShopMetafields');
				const metafields = data?.metafields;
				if (!metafields) throw new Error('No metafields found');
				localStorage.setItem(
					'ShopMetafields',
					JSON.stringify(metafields)
				);
				setGlobalMetafields(metafields);
				const rotatingTextBannerMessages =
					metafields.find(
						({ key }) => key === 'rotating_text_banner_messages'
					)?.value || null;
				setNews(rotatingTextBannerMessages);

				return metafields;
			} catch (error) {
				const isServerError = error?.response?.status >= 500;
				if (isServerError) {
					submitErrorToSlack('/api/getShopMetafields', error, 'GET');
				}
				console.error(error);
			}
		};
		setMetafields();
	}, []);

	useEffect(() => {
		if (!isBrowser) return;
		const updateUserDataLayer = async () => {
			const isLoggedIn = customerData?.customer;
			const userProperties = isLoggedIn
				? {
						visitor_type: 'logged_in',
						customer_id: customerDataContext?.id,
						customer_email: customerDataContext?.email,
						customer_order_count:
							customerDataContext?.orders?.edges?.length,
						customer_total_spent: customerDataContext?.amountSpent,
						customer_tags: customerDataContext?.tags,
						user_consent: 'yes|no|no_interaction',
				  }
				: { visitor_type: 'guest' };

			if (isLoggedIn && !customerDataContext) {
				const customer = await customerFetch(
					JSON.stringify(customerQuery(customerData.customer.id))
				);
				setCustomerData(customer);
			}

			window?.dataLayer?.push({
				event: 'dl_user_data',
				event_id: uuid(),
				cart_total: cart?.cost?.totalAmount?.amount,
				user_properties: userProperties,
			});
		};
		updateUserDataLayer();
	}, [location, cart?.lines?.edges?.length]);

	useEffect(() => {
		const launcherElement = document.getElementById('launcher');
		if (!launcherElement) return;
		if (location.pathname.includes('products/') && width < 990) {
			launcherElement.classList.add('launcher-mobile');
		} else {
			launcherElement.classList.remove('launcher-mobile');
			launcherElement.classList.add('launcher-desktop');
		}
	}, [location, width]);

	const handleSubmitForm = (e) => {
		e.preventDefault();
		setIsAuth(password === 'colour');
	};

	if (!isAuth) {
		return (
			<Password
				password={password}
				setPassword={setPassword}
				handleSubmitForm={handleSubmitForm}
			/>
		);
	}

	return (
		<ErrorBoundary FallbackComponent={ErrorPage}>
			<Header
				pageHandle={location?.pathname}
				location={location}
				news={news}
			/>

			<main>{children}</main>
			<Suspense fallback={<Loader />}>
				<ShotModalContainer />
				<Modal />
				<Notification />
			</Suspense>

			<Cart productsList={allProducts} />
			<Overlay />

			{!shouldHideFooter && (
				<Suspense fallback={<Loader />}>
					<Footer location={location} />
				</Suspense>
			)}
		</ErrorBoundary>
	);
};

export default Layout;
