// External
import React, { useContext, useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import axios from 'axios';

// Internal
import Fields from '../../../components/Fields';
import AccountForgotPassword from '../../../templates/account/AccountForgotPassword';
import { StoreContext } from '../../../context/store-context';
import { submitErrorToSlack } from '../../../utils/slackMessage';
import {
	setHrefLangTag,
	removeHrefLangTag,
} from '../../../helpers/setHrefLang';

import {
	generateCodeChallenge,
	generateCodeVerifier,
	generateState,
	getQuery,
	goToCheckout,
} from '../../../utils/helpers';
import redirectToCheckoutPage from '../../../helpers/checkout';
import Loader from '../../../components/Loader';

const AccountLogin = () => {
	const { setAuthorized, cart } = useContext(StoreContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [forgotPassForm, setForgotPassForm] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [loadingLogin, setLoadingLogin] = useState(true);
	const showReject = (errors) => {
		if (errors[0].code && errors[0].code === 'UNIDENTIFIED_CUSTOMER') {
			setErrorText('Incorrect email or password');
		}
	};
	const success = (data) => {
		setAuthorized(data.accessToken, email);
		if (redirectToCheckoutPage()) {
			goToCheckout(cart);
		} else {
			navigate('/account');
		}
	};

	const sendForm = async (e) => {
		e.preventDefault();
		const url =
			`https://${process.env.SHOPIFY_STORE_URL}/api/2024-04/graphql.json`.replace(
				/\s+/g,
				''
			);

		const myHeaders = {
			'X-Shopify-Storefront-Access-Token':
				process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
			'Content-Type': 'application/json',
		};

		const graphql = JSON.stringify({
			query: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
				customerAccessTokenCreate(input: $input) {
				  customerUserErrors {
					code
					field
					message
				  }
				  customerAccessToken {
					accessToken
					expiresAt
				  }
				}
			  }`,
			variables: { input: { email, password } },
		});
		try {
			const response = await axios.post(url, graphql, {
				headers: myHeaders,
			});

			if (!response?.data) {
				throw new Error('API request failed');
			}

			const customer = response.data?.data?.customerAccessTokenCreate;

			if (customer.customerUserErrors.length > 0) {
				return showReject(customer.customerUserErrors);
			}
			return success(customer.customerAccessToken);
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			console.error(error);
			return error;
		}
	};

	const handleLoginWithPasswordLess = async () => {
		const clientId = `${process.env.GATSBY_CUSTOMER_API_CLIENT_ID}`;
		const shopId = `${process.env.GATSBY_SHOP_ID}`;
		try {
			const authorizationRequestUrl = new URL(
				`https://shopify.com/authentication/${shopId}/oauth/authorize`
			);

			authorizationRequestUrl.searchParams.append(
				'scope',
				'openid email customer-account-api:full'
			);
			authorizationRequestUrl.searchParams.append('client_id', clientId);
			authorizationRequestUrl.searchParams.append(
				'response_type',
				'code'
			);
			authorizationRequestUrl.searchParams.append(
				'redirect_uri',
				`https://joshwoodcolour.com/account/login`
			);
			const state = await generateState();
			authorizationRequestUrl.searchParams.append('state', state);
			authorizationRequestUrl.searchParams.append('ui_locales', 'en');

			const verifier = await generateCodeVerifier();
			const challenge = await generateCodeChallenge(verifier);
			localStorage.setItem('code-verifier', verifier);

			authorizationRequestUrl.searchParams.append(
				'code_challenge',
				challenge
			);
			authorizationRequestUrl.searchParams.append(
				'code_challenge_method',
				'S256'
			);

			window.location.href = authorizationRequestUrl.toString();
		} catch (error) {
			console.error('Error in handlePasswordLess -> AccountLogin', error);
		}
	};

	const getCustomerEmail = async (access_token) => {
		const shopId = `${process.env.GATSBY_SHOP_ID}`;
		const response = await fetch(
			`https://shopify.com/${shopId}/account/customer/api/2025-01/graphql`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${access_token}`,
				},
				body: JSON.stringify({
					operationName: 'GetEmailCustomer',
					query: 'query { customer { emailAddress { emailAddress }}}',
					variables: {},
				}),
			},
		);
		const { data } = await response.json();
		return data?.customer?.emailAddress?.emailAddress;
	};

	const getTokenMultipass = async (email) => {
		try {
			const multipassResponse = await axios.post('/api/multipassLogin', {
				customerEmail: email,
				isToken: true
			});
			return multipassResponse?.data?.token;
		} catch (error) {
			console.error(error);
		}
	}

	const getTokenCustomer = async (token) => {
		const url =
			`https://${process.env.SHOPIFY_STORE_URL}/api/2024-04/graphql.json`.replace(
				/\s+/g,
				''
			);
		const graphql = JSON.stringify({
			query: `mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
				customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
				  customerUserErrors {
					code
					field
					message
				  }
				  customerAccessToken {
					accessToken
					expiresAt
				  }
				}
			  }`,
			variables: {
				multipassToken: token,
			},
		});
		const myHeaders = {
			'X-Shopify-Storefront-Access-Token':
				'35d256955a7094eae68cb0f0aeb3257a',
			'Content-Type': 'application/json',
		};
		try {
			const response = await axios.post(url, graphql, {
				headers: myHeaders,
			});
			if (!response.data) {
				throw new Error('API request failed');
			}
			const data = response.data.data;
			return data?.customerAccessTokenCreateWithMultipass?.customerAccessToken
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack('/api/request', error, 'POST');
			}
			console.error(error);
			return error;
		}
	};

	const generateAccessTokenPasswordLess = async (code) => {
		const codeBase64 = code
			.replace(/=/g, '')
			.replace(/\+/g, '-')
			.replace(/\//g, '_');
		const codeVerifier = localStorage.getItem('code-verifier');
		const headers = {
			'Content-Type': 'application/x-www-form-urlencoded',
		};
		const clientId = `${process.env.GATSBY_CUSTOMER_API_CLIENT_ID}`;
		const shopId = `${process.env.GATSBY_SHOP_ID}`;
		try {
			const response = await fetch(`https://shopify.com/authentication/${shopId}/oauth/token`, {
				method: 'POST',
				headers,
				body: new URLSearchParams({
					grant_type: 'authorization_code',
					client_id: clientId,
					redirect_uri: `https://joshwoodcolour.com/account/login`,
					code: codeBase64,
					code_verifier: codeVerifier
				}),
			});

			const { access_token, expires_in, id_token, refresh_token } =
				await response.json();

			if (!access_token) {
				throw new Error('Error in generating access token');
			}

			sessionStorage.setItem('is_logged_in_password_less', 'true');
			sessionStorage.setItem('access_token', access_token);
			sessionStorage.setItem('expires_in', expires_in);
			sessionStorage.setItem('id_token', id_token);
			sessionStorage.setItem('refresh_token', refresh_token);

			const emailCustomer = await getCustomerEmail(access_token);
			const tokenMultipass = await getTokenMultipass(emailCustomer);
			const accessTokenCustomer = await getTokenCustomer(tokenMultipass);
			sessionStorage.setItem('tokenCustomer', accessTokenCustomer?.accessToken);

			setAuthorized(access_token, emailCustomer, accessTokenCustomer?.accessToken);
			navigate('/account');
		} catch (error) {
			console.error(error)
		} finally {
			setLoadingLogin(false);
		}
	}


	useEffect(() => {
		setHrefLangTag('login-hreflang');

		return () => {
			removeHrefLangTag('login-hreflang');
		};
	}, []);

	useEffect(() => {
		const codeQuery = getQuery('code');
		if (codeQuery) {
			setLoadingLogin(true);
			generateAccessTokenPasswordLess(codeQuery);
		} else {
			setLoadingLogin(false);
		}
	}, [])

	if (loadingLogin) return (<div className='signIn-form__loader'><Loader /></div>)

	return forgotPassForm ? (
		<>
			<AccountForgotPassword />
			<div className='signIn-form__resetPass'>
				<button onClick={() => setForgotPassForm(false)} type='button'>
					Cancel
				</button>
			</div>
		</>
	) : (
		<form
			onSubmit={sendForm}
			action='/'
			name='signin'
			className='account__login-form signIn-form'
		>
			<h1 className='signIn-form__title'>Login</h1>
			{errorText.length > 0 && (
				<div
					className={`account__message 
                form-message form-message--error`}
				>
					<h2 className='h3 form-message__title ' data-form-status=''>
						Please adjust the following:
					</h2>
					<div className='errors'>
						<ul>
							<li>{errorText}</li>
						</ul>
					</div>
				</div>
			)}
			<div className='signIn-form__fields'>
				<div className='container-form'>
					<div className='label-form'>Email</div>
					<input
						type='email'
						className='input-form'
						placeholder=''
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>
				<div className='container-form'>
					<div className='label-form'>Password</div>
					<input
						type='Password'
						className='input-form'
						placeholder=''
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
			</div>
			<div className='signIn-form__resetPass signIn-form__resetPass-passwordLess'>
				<button onClick={() => setForgotPassForm(true)} type='button'>
					Forgot your password?
				</button>

				<button onClick={handleLoginWithPasswordLess} type='button'>
					Login without password?
				</button>
			</div>

			<button
				type='submit'
				className='signIn-form__button signIn-form-button button button--dark'
			>
				<span className='signIn-form-button__text'>Sign in</span>
			</button>

			<div className='signIn-form__create'>
				<Link to='/account/register'>Create account</Link>
			</div>
		</form>
	);
};

export default AccountLogin;
