// External
import React from 'react';
import { Link } from 'gatsby';

// Internal
import useBreadcrumb from '../../helpers/useBreadcrumb';

import {
	BLOG_SECTION_BREADCRUMB,
	EXPERTS_SECTION_BREADCRUMB,
	PRODUCT_SECTION_BREADCUMB,
} from '../../constants/breadcrumbs';

import './Breadcrumb.scss';

const Breadcrumb = ({ section, article, product }) => {
	const { breadcrumb, breadcrumbLink, currentSection } = useBreadcrumb(
		section,
		article,
		product
	);

	const handleCurrentSectionLink = () => {
		if (section === BLOG_SECTION_BREADCRUMB) return '/blogs/community';
		if (section === PRODUCT_SECTION_BREADCUMB) return '/collections/all-products';
		if (section === EXPERTS_SECTION_BREADCRUMB) return '/pages/josh-wood-colour-hair-salon-london/';
	};

	return (
		<div className='bread-crumb--container'>
			<ol itemScope itemType="https://schema.org/BreadcrumbList">
				<li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
					<Link to='/' itemProp="item">
						<span itemProp="name">Home</span>
					</Link>
					<meta itemProp="position" content="1" />
				</li>
				{(currentSection || section) && (
					<li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
						<img
							src='/images/icons/breadcrum-separator.png'
							alt=''
							aria-hidden="true"
							loading='lazy'
						/>
						<Link to={handleCurrentSectionLink()} itemProp="item">
							<span itemProp="name">{currentSection || section}</span>
						</Link>
						<meta itemProp="position" content="2" />
					</li>
				)}
				{breadcrumb && (
					<li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
						<img
							src='/images/icons/breadcrum-separator.png'
							alt=''
							aria-hidden="true"
							loading='lazy'
						/>
						{breadcrumbLink ? (
							<Link to={breadcrumbLink} itemProp="item">
								<span itemProp="name">{breadcrumb}</span>
							</Link>
						) : (
							<span itemProp="name">{breadcrumb}</span>
						)}
						<meta itemProp="position" content="3" />
					</li>
				)}
			</ol>
		</div>
	);
};

export default Breadcrumb;
