// External
import React from 'react';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import LevelListItem from './LevelListItem';
import ProductSelectionLevelHeader from './ProductSelectionLevelHeader';
import PreviewSecondLevel from './PreviewSecondLevel';
import { getMetafield } from '../../../../../utils/helpers';

const ProductSelectionLevelTwo = ({
	activeStep = false,
	nextStep = false,
	isMobile = false,
	isFilterActive,
	items,
	setActiveSecondLevel,
	activeSecondLevel,
	activeFirstLevel,
	errorSelectLevel,
	skipAddingMiracleShotAutomatically = false,
}) => {
	const previewSecondLevel = [
		'chestnut-brunette',
		'smoky-brunette',
		'champagne',
		'icy',
	];
	const title = activeSecondLevel && activeSecondLevel.title.split('+')[1];
	const description = getMetafield(activeSecondLevel, 'description') || '';
	const classnamesCondition = cn(
		'pdp-selection-level-row-v2__img ' +
			'pdp-selection-level-row-v2__img--condition',
		{ 'pdp-selection-level-row-v2__img--active': activeSecondLevel }
	);
	const conditionImg =
		'/images/miracle/steps/tones/pdp/miracle-condition.png';

	const element = items?.map((item) => (
		<LevelListItem
			isFilterActive={isFilterActive}
			activeStep={activeStep}
			nextStep={nextStep}
			isSecondLevel
			key={item.storefrontId}
			item={item}
			setActiveLevel={setActiveSecondLevel}
			activeLevel={activeSecondLevel}
		/>
	));

	const levelTwoErrorName = () => {
		if (title) return false;
		if (
			activeFirstLevel &&
			!activeSecondLevel &&
			errorSelectLevel.errorLevelTwo
		)
			return errorSelectLevel.errorLevelTwo;
	};

	return (
		<div
			className={cn('pdp-selection-level', {
				'pdp-selection-level--disabled': !activeFirstLevel,
			})}
		>
			{!isMobile && (
				<ProductSelectionLevelHeader
					title='Choose tone (Shade Shot)'
					name={title}
					description={description}
					errorLevel={levelTwoErrorName}
				/>
			)}
			<div className='pdp-selection-level__flex'>
				<div className='pdp-selection-level__row pdp-selection-level-row-v2'>
					{!activeFirstLevel ? (
						<PreviewSecondLevel items={previewSecondLevel} />
					) : (
						element
					)}
					{!skipAddingMiracleShotAutomatically && (
						<>
							<span className='pdp-selection-level__plus'>+</span>
							<div className={classnamesCondition}>
								<LazyLoadImage
									src={conditionImg}
									alt='condition'
									effect='blur'
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductSelectionLevelTwo;
