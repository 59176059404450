import React from 'react';
import { Link } from 'gatsby';

import ButtonGradient from '../../ButtonGradient';
import LocalImage from '../../LocalImage';

import './CombineCollections.scss';

const CombineCollections = ({ collections }) => {
	const formatCollections = collections && JSON.parse(collections) || null;
	return (
		<section className='product-categories'>
			<h2 className='product-categories__heading'>
				{formatCollections?.headingTitle}
			</h2>
			<p className='product-categories__subheading'>
				{formatCollections?.descriptionSection}
			</p>
			<div className='product-categories__grid'>
				{formatCollections?.collections?.map((collection) => (
					<Link
						to={`/collections/${collection?.handle}`}
						key={`${collection?.title}`}
					>
						<div className='product-card'>
							<LocalImage
								image={`/home-page/${collection?.['image-name']}`}
								alt={collection?.title}
							/>
							<div className='product-card__container'>
								<div className='product-card__container-text'>
									<h3 className='product-card__title'>
										{collection?.title}
									</h3>
									<p className='product-card__description'>
										{collection?.description}
									</p>
								</div>
								<ButtonGradient
									isLink
									linkUrl={`/collections/${collection?.handle}`}
									buttonText='Shop now'
									isPinkOutline
								/>
							</div>
						</div>
					</Link>
				))}
			</div>
		</section>
	);
};

export default CombineCollections;
