import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import LocalImage from '../../components/LocalImage';

const AboutSection = ({ column, reverse, title, text, imgUrl, buttons }) => (
	<section
		className={cn(
			'about-section',
			{ 'about-section--column': column },
			{ 'about-section--reverse-row': reverse && !column },
			{ 'about-section--reverse-column': reverse && column }
		)}
	>
		<div className='container'>
			<div className='about-section__flex'>
				{imgUrl && (
					<div className='about-section__column'>
						<LocalImage
							className='about-section__image'
							image={imgUrl}
							alt={title}
						/>
					</div>
				)}
				<div className='about-section__column'>
					{title && <h3 className='about-section__title'>{title}</h3>}
					{text && <p className='about-section__text'>{text}</p>}
					{buttons && (
						<div className='about-section__buttons'>
							{buttons.map((item) => (
								<Link
									key={item.title}
									className='about-section__link button'
									to={item.url}
								>
									{item.title}
								</Link>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	</section>
);

export default AboutSection;
