import React from 'react';
import { Link, navigate } from 'gatsby';
import './Dropdown.scss';

const DropdownMenu = ({ items, parentId }) => (
	<ul className='dropdown-menu' id={`dropdown-${parentId}`}>
		{items.map(({ _uid, title, url }, index) => (
			<div
				key={`${_uid}-${index + 1}`}
				className='dropdown-menu__item'
				onClick={() => navigate(url?.url)}
			>
				<Link className='dropdown-menu__link' to={url?.url}>
					{title}
				</Link>
			</div>
		))}
	</ul>
);

export default DropdownMenu;
