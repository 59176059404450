// BLOG
export const BLOG_SECTION_BREADCRUMB = 'Blog';

// PRODUCTS
export const PRODUCT_SECTION_BREADCUMB = 'All Products';
export const HAIR_SECTION_CORCERNS = 'Hair concerns';

export const PRODUCTS_BREADCRUMBS = {
	'/collections/best-selling-products': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Best Selling Hair Colour & Care',
	},
	'/collections/colour-correctors': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Colour Toners & Correctors',
	},
	'/collections/hair-care': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Care Products',
	},
	'/collections/permanent-hair-colour-reduces-breakage-miracle-range': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'The Miracle System',
	},
	'/collections/hair-colouring-accessories': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Colouring Accessories & Kits',
	},
	'/collections/gift-sets': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Colour & Care Gift Sets',
	},
	'/collections/hair-care': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Care Products',
	},
	'/collections/permanent-hair-colour': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Colour',
	},
	'/collections/hair-gloss-and-glaze': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Gloss and Glaze Treatment',
	},
	'/collections/root-touch-up': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Root Touch-Up',
	},
	'/collections/cover-grey-hair': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Colour to Cover Grey Hair',
	},
	'/collections/fix-tone-brassy-hair': {
		section: HAIR_SECTION_CORCERNS,
		breadcrumb: 'Brassy colour',
	},
	'/collections/keep-balayage-from-fading': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Keep Balayage from Fading',
	},
	'/collections/ammonia-free-hair-colour': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Ammonia Free Hair Colour',
	},
	'/collections/refresh-your-hair-colour': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Refresh Your Hair Colour',
	},
	'/collections/hair-colour-sensitive-scalp': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Colour for Sensitive Scalp',
	},
	'/collections/stop-hair-breakage': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Stop Hair Breakage',
	},
	'/collections/root-spray': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Root Touch-up Spray (Airbrush)',
	},
	'/collections/root-touch-up-brushes': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Root Touch-up Brushes',
	},
	'/collections/brown-hair-colour': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Brown & Brunette Hair Colour',
	},
	'/collections/blonde-hair-colour': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Blonde Hair Colour Products',
	},
	'/collections/root-marker-pens': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Grey Root Touch-up Pens',
	},
	'/collections/shade-shot': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Shade Shot Toner',
	},
	'/collections/grey-coverage': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Hair Colour to Cover Grey Hair',
	},
	'/collections/shampoos': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Professional Hair Shampoos',
	},
	'/collections/vegan-hair-colour-hair-care': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Vegan Hair Colour & Hair Care',
	},
	'/collections/thismorning': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: "Josh's This Morning Looks",
	},
	'/collections/conditioners': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Professional Hair Conditioners',
	},
	'/collections/products-for-red-hair-1': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Red Hair Colour Products',
	},
	'/collections/how-to-get-auburn-hair': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Get Auburn Hair Colour',
	},
	'/collections/products-for-black-hair': {
		section: PRODUCT_SECTION_BREADCUMB,
		breadcrumb: 'Products for Black Hair',
	},
};

// Experts
export const EXPERTS_SECTION_BREADCRUMB = 'Salon';

export const EXPERTS_BREADCRUMBS = {
	'/pages/meet-the-experts': {
		section: EXPERTS_SECTION_BREADCRUMB,
		breadcrumb: 'Experts',
	},
	'/pages/josh-wood-colour-hair-salon-london': {
		section: EXPERTS_SECTION_BREADCRUMB,
		breadcrumb: 'Atelier',
	},
	'/josh-wood-colour-hair-salon-claridges': {
		section: EXPERTS_SECTION_BREADCRUMB,
		breadcrumb: "Claridge's",
	},
	'/pages/hair-salon-price-list': {
		section: EXPERTS_SECTION_BREADCRUMB,
		breadcrumb: 'Services',
	},
};
