import React, { memo, useContext, useState } from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';

import HeaderNavV2 from './HeaderNavV2';
import HeaderTop from '../Header/HeaderTop';
import HeaderLogo from '../Header/HeaderLogo';
import MobileDropdown from '../Header/MobileDropdown';

import { useWindowWidth } from '../../utils/hooks';
import { StoreContext } from '../../context/store-context';
import { isBrowser } from '../../utils/helpers';
import SearchModal from '../Search/SearchModal';
import ButtonGradient from '../ButtonGradient';

import '../Header/Header.scss';

const HeaderV2 = ({ scrollY, headerRef, announcement, news, quantity }) => {
	const { openCart, menu } = useContext(StoreContext);
	const windowWidth = useWindowWidth();
	const [isDropdownOpened, setDropdownOpen] = useState(false);
	const [showSearchModal, setShowSearchModal] = useState(false);

	const isMiraclePage = isBrowser && window.location?.pathname === '/miracle';
	const classes = cn('header-section', {
		'header-section--scrolled': scrollY > 70 && !isMiraclePage,
		'header-section--miracle-scrolled': scrollY > 70 && isMiraclePage,
	});

	const isVisibleMenuHeader = windowWidth >= 1300;

	return (
		<>
			<div className={classes} ref={headerRef}>
				{!isMiraclePage && (
					<HeaderTop
						announcement={announcement}
						windowWidth={windowWidth}
						news={news}
						isHeaderV2
					/>
				)}
				<header className='header'>
					<div className='header__container header-v2__container'>
						<div className='header-nav'>
							{isVisibleMenuHeader ? (
								<HeaderLogo />
							) : (
								<>
									<button
										type='button'
										aria-label='header burger'
										className='header__burger'
										onClick={() => setDropdownOpen(true)}
									/>
									<button
										className='search-button search-button-v2'
										aria-label='Header search button'
										onClick={() => setShowSearchModal(true)}
									/>
								</>
							)}
						</div>
						<nav>
							<HeaderNavV2 menu={menu?.HeaderCenterMenuV2} />
							{!isVisibleMenuHeader && <HeaderLogo />}
						</nav>
						<div className='header-nav'>
							{isVisibleMenuHeader && (
								<>
									<div className='header-btn'>
										<ButtonGradient
											isLink
											isImageGradient
											linkUrl='/pages/consultation'
											buttonText='Find your colour'
										/>
									</div>
									<button
										className='search-button search-button-v2'
										aria-label='Header search button'
										onClick={() => setShowSearchModal(true)}
									/>
								</>
							)}
							<button
								aria-label='open cart'
								className='header__cart-icon header-v2__cart-icon'
								onClick={openCart}
								aria-hidden='true'
							>
								<span>{quantity}</span>
							</button>
							<Link
								to='/account'
								role='link'
								className='header__user-icon header-v2__user-icon'
							/>
						</div>
					</div>
					<MobileDropdown
						windowWidth={windowWidth}
						mobileSubMenu={(menu?.MobileMenu || []).find(
							(el) => el.children.length
						)}
						socials={menu?.Socials}
						isDropdownOpened={isDropdownOpened}
						setDropdownOpen={setDropdownOpen}
					/>
				</header>
			</div>
			<div
				className={cn('overlay overlay--burger', {
					'overlay--active': isDropdownOpened,
				})}
				role='button'
				aria-label='overlay'
				onClick={() => setDropdownOpen(false)}
				onKeyDown={() => setDropdownOpen(false)}
				tabIndex='0'
			/>
			{showSearchModal && (
				<SearchModal
					closeModal={() => setShowSearchModal(false)}
					isHeaderV2
				/>
			)}
		</>
	);
};

export default memo(HeaderV2);
