import React from 'react';
import { Link } from 'gatsby';

import FooterLogo from '../FooterTop/Logo';
import SocialItem from '../../SocialItem';
import { useWindowWidth } from '../../../utils/hooks';
import ButtonGradient from '../../ButtonGradient';

const FooterLeftSideV2 = ({ socials, location }) => {
	const windowWidth = useWindowWidth();
	return (
		<div className='footer__left-side footer-v2__left-side'>
			<div className='footer-site__logo'>
				<Link to='/'>
					<FooterLogo />
				</Link>
			</div>
			<div className='footer-information'>
				<p className='footer-information__name'>Josh Wood Colour</p>
				<p className='footer-information__address'>
					6 Lansdowne Mews, London W11 3AN
				</p>

				<a
					href='mailto:expert@joshwoodcolour.com'
					className='footer-information__email'
				>
					expert@joshwoodcolour.com
				</a>

			</div>

			{windowWidth > 750 && (
				<>
					<div className='footer-button'>
						<ButtonGradient
							isLink
							linkUrl='/pages/consultation'
							buttonText='Find your colour'
							isBorderGradient
							className='footer-button__gradient'
						/>
					</div>
					<div className='footer__rating footer-v2__rating'>
						<ul className='footer__socials'>
							{(socials || []).map((item) => (
								<SocialItem item={item} key={item.id} />
							))}
						</ul>
					</div>
				</>
			)}
		</div>
	);
};

export default FooterLeftSideV2;
