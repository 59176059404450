import React from 'react';
import { Helmet } from 'react-helmet';
import LocalImage from '../../components/LocalImage';


const AboutPageHeroBanner = ({ headerText }) => (
	<>
		<Helmet>
			<link rel="preload" href="/static/images/about/about-banner.jpg" as="image" />
		</Helmet>
		<div className='about-page-banner'>
			<div className='about-page-banner__container container'>
				<div className='about-page-banner__info'>
					<h1>{headerText}</h1>
					<p>
						The world leading colour expert is called on by celebrities,
						designers and his Atelier clients, for his genius with hair
						colour.
					</p>
				</div>
			</div>
			
			<LocalImage image='about/about-banner.jpg' />
		</div>
	</>
);

export default AboutPageHeroBanner;
