import React, { useContext, useEffect, useState } from 'react';

import {
	getDiscountPrice,
	getMetafield,
	getSellingPlanGroupDefaultValue,
	getSellingPlanGroupOptions,
} from '../../../../utils/helpers';
import { useWindowWidth } from '../../../../utils/hooks';
import { StoreContext } from '../../../../context/store-context';
import YotpoV2 from '../../../../components/Yotpo/YoptoV2';

import ProductInfoTitleV2 from './ProductInfoTitleV2';
import getVariantPrice from '../../../../helpers/productsPricing';
import AboutMiracle from '../../AboutMiracle';
import ProductBundlesSectionV2 from '../ProductBundlesSection';
import ProductOneTestimonial from '../ProductOneTestimonial';

import './ProductInfoV2.scss';

const ProductInfoV2 = ({
	product,
	cart,
	collections,
	shopLookProductsHandles,
	recommendedProduct,
	recommendedProductHandle,
	isRecommendedSelected,
	windowWidth,
	rating,
	sellingPlanGroup,
	miracleSystem,
	activeVariant,
	setActiveVariant,
	scrollToReviews,
	productsList,
	allBaseColours,
	withShadeShot,
	shots,
	shadeShotsProducts,
	isDiscountGroup,
}) => {
	const { subscriptionsEnabled, yotpoRatings } = useContext(StoreContext);

	const isSubscribeOnPDP = getMetafield(product, 'subscription_on_pdp');
	const [groupFound, setGroupFound] = useState(
		Object.keys(sellingPlanGroup).length > 0
	);
	const [canSubscribe, setCanSubscribe] = useState(
		groupFound && subscriptionsEnabled && isSubscribeOnPDP
	);
	const [options, setOptions] = useState(
		canSubscribe
			? getSellingPlanGroupOptions(sellingPlanGroup.sellingPlans.edges)
			: []
	);
	const [activeRadio, setActiveRadio] = useState(
		canSubscribe ? 'auto-delivery' : 'One-Time Purchase'
	);
	const [selectedValue, setSelectedValue] = useState(
		canSubscribe ? getSellingPlanGroupDefaultValue(options) : null
	);
	const [discountPrice, setDiscountPrice] = useState(
		product?.variants[0].price
	);
	const [discountPercentageWithSub, setDiscountPercentageWithSub] =
		useState(0);
	const width = useWindowWidth();
	const isMiracle = getMetafield(product, 'is_miracle_system');
	const isGiftCard =
		product.handle.includes('gift') && !product.handle.includes('comb');
	const optionsObjects = options.map((opt, i) => ({
		value: i,
		label: `Every ${opt} ${opt === '4 Weeks' ? '(most common)' : ''}`,
	}));

	const isVariant = JSON.parse(getMetafield(product, 'is_variant'));
	const isPermHairColourWithVariants = JSON.parse(
		getMetafield(product, 'is_perm_hair_colour_with_variants')
	);
	const usesTestimonial = getMetafield(product, 'uses_testimonial')
	const [selectedVariant, setSelectedVariant] = useState(undefined);

	useEffect(() => {
		const handleFirstVariant = () => {
			if (!product || !isVariant) return null;
			setSelectedVariant(product.variants[0]);
			setActiveVariant(product.variants[0]);
		};
		handleFirstVariant();
	}, [product, isVariant]);

	useEffect(() => {
		const handleSelectedVariant = () => {
			if (!selectedVariant || !isVariant) return;
			const targetDiv = document.querySelectorAll(
				'.target-variant__product'
			);
			for (const variant of targetDiv) {
				if (variant.getAttribute('data-id') === selectedVariant.id) {
					variant.style.border = '3px solid pink';
				} else {
					variant.style.border = '3px solid transparent';
				}
			}
		};
		handleSelectedVariant();
	}, [selectedVariant, isVariant]);

	useEffect(() => {
		if (canSubscribe) {
			const plan = sellingPlanGroup.sellingPlans.edges[selectedValue];
			const productPrice = parseInt(product?.variants[0].price);
			const discountPercentage =
				plan?.node.pricingPolicies[0].adjustmentValue.percentage;
			const discount = getDiscountPrice(productPrice, discountPercentage);
			setDiscountPercentageWithSub(discountPercentage);
			setDiscountPrice(discount);
		}
	}, [canSubscribe]);
	return (
		<div className='pdp-v2-info'>
			{!isGiftCard && (
				<>
					{rating && (
						<YotpoV2
							rating={rating}
							scrollToReviews={scrollToReviews}
							showCount
						/>
					)}

					<ProductInfoTitleV2
						product={product}
						productsList={productsList}
						allBaseColours={allBaseColours}
						withShadeShot={withShadeShot}
						shots={shots}
						shadeShotsProducts={shadeShotsProducts}
						sellingPlanGroup={sellingPlanGroup}
						miracleSystem={miracleSystem}
						id='normal-title'
					/>

					{canSubscribe ? (
						<div className='pdp-v2-info__price-pdp'>
							<p>
								From{' '}
								<span>
									{getVariantPrice(product, true, 15)}
								</span>{' '}
								(Free shipping + free gifts)*
							</p>
						</div>
					) : (
						<div className='pdp-v2-info__price-pdp pdp-v2-info__price-pdp-no-subscription'>
							<p>
								<span>
									{getVariantPrice(product, true, 0)}
								</span>
							</p>
						</div>
					)}

					{isMiracle && <AboutMiracle product={product} />}

					<ProductBundlesSectionV2
						{...{
							product,
							cart,
							collections,
							shopLookProductsHandles,
							recommendedProduct,
							recommendedProductHandle,
							isRecommendedSelected,
							windowWidth,
							rating,
							canSubscribe,
							subscriptionsEnabled,
							discountPrice,
							activeRadio,
							setActiveRadio,
							selectedValue,
							setSelectedValue,
							sellingPlanGroup,
							optionsObjects,
							activeVariant,
							setActiveVariant,
							isGiftCard,
							discountPercentageWithSub,
							selectedVariant,
						}}
						options={options}
					/>

					{usesTestimonial && <ProductOneTestimonial product={product} />}
				</>
			)}
		</div>
	);
};

export default ProductInfoV2;
