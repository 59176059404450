import React from 'react';
import { isNumber } from 'lodash';

import Loader from '../Loader';
import './Yotpo.scss';

const StarRating = React.lazy(() => import('react-svg-star-rating'));

const YotpoV2 = ({
	rating,
	scrollToReviews = null,
	showCount = false,
	size = 10,
	isHomepage = false,
}) => {
	const product_score = isNumber(rating?.product_score)
		? rating?.product_score.toFixed(1)
		: 0;
	return (
		<button
			className={`product-rating__scroll-trigger ${
				isHomepage && 'homepage-reviews'
			}`}
			onClick={scrollToReviews}
		>
			<React.Suspense fallback={<Loader />}>
				<StarRating
					starClassName='product-rating__star'
					containerClassName='product-rating'
					initialRating={rating?.product_score}
					roundedCorner='false'
					activeColor='#000'
					emptyColor='#fff'
					isReadOnly='true'
					unit='float'
					size={size}
				/>
			</React.Suspense>
			{showCount && (
				<span className='product-rating__count'>
					{product_score}/5 based on {rating?.total_reviews} reviews
				</span>
			)}
		</button>
	);
};

export default YotpoV2;
