// External
import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';



// Internal
import ProductFirstScreen from './ProductFirstScreen';
import ProductPairWithContainer from './ProductPairWith';
import Loader from '../../components/Loader';
import {
	getMetafieldsObject,
	isBrowser,
	getMetafield,
	sendViewItemEvent,
} from '../../utils/helpers';

import { PRODUCT_SECTION_BREADCUMB } from '../../constants/breadcrumbs';

const AlternativeLooks = lazy(() => import('./AlternativeLooks'));
const ProductFrequently = lazy(() => import('./ProductFrequently'));
const ProductTalks = lazy(() => import('./ProductTalks'));
const ProductHighlights = lazy(() =>
	import('./ProductFirstScreen/ProductInfo/ProductHighlights')
);
const VariantInformation = lazy(() =>
	import('./ProductFirstScreen/ProductInfo/VariantInformation')
);
const ProductWow = lazy(() =>
	import('./ProductFirstScreen/ProductInfo/ProductWow')
);
const Klaviyo = lazy(() => import('../../components/Klaviyo'));
const Breadcrumb = lazy(() => import('../../components/BreadCrumb/Index'));
const YotpoWidget = lazy(() => import('../../components/Yotpo/YotpoWidget'));
const ProductPerfectBundle = lazy(() => import('./ProductPerfectBundle'));
const ProductAwardsContainer = lazy(() => import('./ProductAwards/ProductAwardsContainer'));
const ProductClientSliderContainer = lazy(() =>
	import('./ProductClientsSlider/ProductClientSliderContainer')
);
const ProductHowUse = lazy(() => import('./ProductHowUse/ProductHowUse'));

const ProductPage = ({
	product = {},
	collections,
	cart,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	isPermanentColour,
	rating,
	sellingPlanGroup,
	MiracleSystemSellingPlanGroup,
}) => {
	const frequentlyMainProductHandle = getMetafield(
		product,
		'frequently_main_product'
	);
	const frequentlyProductsHandles = getMetafield(
		product,
		'frequently_products'
	);

	const alternativeLooks = getMetafield(product, 'alternative_looks');
	const hideJoshTalks = getMetafield(product, 'hide_josh_talks_section');
	const hideAccolades = getMetafield(product, 'hid_accolades_sections');
	const hideClientSay = getMetafield(product, 'hide_clients_say_section');
	const hideHowToUse = getMetafield(product, 'hide_how_to_use_section');

	const isVariant = JSON.parse(getMetafield(product, 'is_variant'));
	const isRootSmudge = JSON.parse(getMetafield(product, 'is_root_smudge'));

	const hidePairWith = getMetafield(product, 'hide_pairs_with_section');
	const productHasPerfectBundle = getMetafield(product, 'perfect_bundle');
	const [activeVariant, setActiveVariant] = useState(null);

	const yotpoRef = useRef(null);

	const productMetafield = getMetafieldsObject(product?.metafields);

	const scrollToReviews = () =>
		window.scrollTo({
			top: yotpoRef.current.getBoundingClientRect().top - 40,
			behavior: 'smooth',
		});

	useEffect(() => {
		isBrowser && sendViewItemEvent(product);
	}, []);

	useEffect(() => {
		const item = {
			ProductName: product.title,
			ProductID: product.id,
			Brand: 'Josh Wood Colour',
			Price: `£${product.variants[0].price}`,
			imageURL: product.media[0].preview.image.originalSrc,
			URL: window.location.href,
		};
		window.klaviyo?.push(['track', 'Viewed Product', item]);
	}, [product]);

	return (
		<section className='pdp'>
			<Breadcrumb section={PRODUCT_SECTION_BREADCUMB} product={product} />
			<ProductFirstScreen
				{...{
					product,
					collections,
					cart,
					allBaseColours,
					productsList,
					shadeShotsProducts,
					isPermanentColour,
					activeVariant,
					setActiveVariant,
					rating,
					scrollToReviews,
					sellingPlanGroup,
					MiracleSystemSellingPlanGroup,
				}}
			/>
			{isVariant && isRootSmudge && (
				<>
					<section className='pdp-variant__container'>
						<Suspense fallback={<Loader />}>
							<ProductHighlights
								productMetafield={productMetafield}
							/>
						</Suspense>
						<Suspense fallback={<Loader />}>
							<VariantInformation product={product} />
						</Suspense>
					</section>
					<section className='pdp-variant--benefits__container'>
						<Suspense fallback={<Loader />}>
							<ProductWow productMetafield={productMetafield} />
						</Suspense>
					</section>
				</>
			)}
			{!hidePairWith && (
				<section>
					<Suspense fallback={<Loader />}>
						<ProductPairWithContainer
							product={product}
							cart={cart}
						/>
					</Suspense>
				</section>
			)}
			<section className='pdp-klaviyo'>
				<div className='pdp-klaviyo__container container'>
					<Suspense fallback={<Loader />}>
						<Klaviyo id={process.env.KLAVIYO_PDP_FORM_ID} />
					</Suspense>
				</div>
			</section>
			{alternativeLooks && (
				<section>
					<Suspense fallback={<Loader />}>
						<AlternativeLooks
							alternativeLooks={alternativeLooks.split('|')}
						/>
					</Suspense>
				</section>
			)}
			{frequentlyMainProductHandle && frequentlyProductsHandles ? (
				<section>
					<Suspense fallback={<Loader />}>
						<ProductFrequently
							frequentlyMainProductHandle={
								frequentlyMainProductHandle
							}
							frequentlyProductsHandles={frequentlyProductsHandles.split(
								'|'
							)}
						/>
					</Suspense>
				</section>
			) : null}
			{!hideJoshTalks && (
				<section>
					<Suspense fallback={<Loader />}>
						<ProductTalks
							product={product}
							activeVariant={activeVariant}
						/>
					</Suspense>
				</section>
			)}
			{!hideAccolades && (
				<section>
					<Suspense fallback={<Loader />}>
						<ProductAwardsContainer product={product} />
					</Suspense>
				</section>
			)}
			{!hideClientSay && (
				<section>
					<Suspense fallback={<Loader />}>
						<ProductClientSliderContainer product={product} />
					</Suspense>
				</section>
			)}
			{!hideHowToUse && (
				<section>
					<Suspense fallback={<Loader />}>
						<ProductHowUse
							product={product}
							activeVariant={activeVariant}
						/>
					</Suspense>
				</section>
			)}
			{productHasPerfectBundle && (
				<section>
					<Suspense fallback={<Loader />}>
						<ProductPerfectBundle product={product} cart={cart} />
					</Suspense>
				</section>
			)}

			<section>
				<Suspense fallback={<Loader />}>
					<YotpoWidget yotpoRef={yotpoRef} product={product} />
				</Suspense>
			</section>
		</section>
	);
};

export default ProductPage;
