import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Button from '../../Button';
import icon from '../../../../static/images/info-icon.svg';
import './NormalProduct.scss';

const NormalProduct = ({ product, description, href }) => 
(
		<section className='normal-product-upsell'>
			<section className='normal-product-upsell__image'>
				<GatsbyImage
					image={product?.media[0]?.preview.image.gatsbyImageData}
					className='normal-product-upsell__image-img'
				/>

				<Link to={href}>
					<img
						src={icon}
						className='normal-product-upsell__image-icon'
						alt='text'
					/>
					<p>more info</p>
				</Link>
			</section>
			<section className='normal-product-upsell__content'>
				<section className='normal-product-upsell__content--title'>
					<h5>{product?.title}</h5>
					<p>£{product?.variants[0]?.price}</p>
				</section>
				<section className='normal-product-upsell__content--description'>
					<p>
						{description}
					</p>
				</section>
				<Button
					product={product}
					isDark
					isPdp
					availableForSale={product?.variants[0]?.availableForSale}
				/>
			</section>
		</section>
	);


export default NormalProduct;
