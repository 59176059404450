// External
import React, { useContext, useEffect, useRef, useState } from 'react';

// Internal
import { StoreContext } from '../../context/store-context';
import HeaderV2 from '../HeaderV2/HeaderV2';
import { isBrowser } from '../../utils/helpers';
import { useQuantity } from '../../utils/hooks';

const HeaderContainer = ({ pageHandle, location, news }) => {
	const headerRef = useRef();
	const [hasItems, quantity] = useQuantity();
	const [scrollY, setScrollY] = useState(0);
	const { setAuthorized } = useContext(StoreContext);

	useEffect(() => {
		const handleCurrentCredentials = () => {
			const accessToken = window.sessionStorage.getItem('accessToken');
			const userEmail = window.sessionStorage.getItem('userEmail');
			if (isBrowser && accessToken && userEmail) {
				setAuthorized(accessToken, userEmail);
			}
		};
		handleCurrentCredentials();
	}, []);

	const handleScroll = () => {
		if (headerRef.current) {
			const position = window.pageYOffset;
			setScrollY(position);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<HeaderV2
			{...{
				pageHandle,
				hasItems,
				quantity,
				location,
				headerRef,
				scrollY,
				news,
			}}
		/>
	);
};

export default HeaderContainer;
