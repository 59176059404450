import React from 'react';

import TrustPilot from '../../TrustPilot';
import ButtonGradient from '../../ButtonGradient';
import ProductOneTestimonial from '../../../templates/products-v2/ProductFirstScreenV2/ProductOneTestimonial';
import LocalImage from '../../LocalImage';

import './TrustInResults.scss';

const TrustInResults = () => (
	<section id='trust-in-results' className='trust-results'>
		<div className='trust-results__content'>
			<div className='trust-results__content-reviews'>
				<TrustPilot id='5419b6ffb0d04a076446a9af' height='25px' />
			</div>

			<h2>Nervous about colouring your hair at home?</h2>

			<p className='trust-results__content-subtitle'>
				With Josh Wood Colour all you’ll feel is confident in your
				results.
			</p>

			<p className='trust-results__content-desc'>
				Salon-quality products, zero damage to your hair, and expert
				help just a click away. Transform your look with confidence and
				enjoy the compliments that follow!
			</p>

			<div className='trust-results__content-buttons'>
				<ButtonGradient
					isLink
					isPink
					linkUrl='/products/miracle-system'
					buttonText='Shop the Miracle System'
					isPinkFullWidth
				/>
				<ButtonGradient
					isLink
					isPinkOutline
					linkUrl='/miracle-steps'
					buttonText='Help find my shade'
				/>
			</div>

			<div className='trust-results__content-review'>
				<div className='trust-results__content-review-img'>
					<LocalImage
						image='/pdp/customers/woman-2.png'
						alt='Customer review'
					/>
				</div>
				<div className='trust-results__content-review-review'>
					<ProductOneTestimonial isHomePage />
				</div>
			</div>
		</div>
		<div className='trust-results__image'>
			<LocalImage
				image='/home-page/miracle-system.png'
				alt='Customer review'
			/>
		</div>
	</section>
);

export default TrustInResults;
