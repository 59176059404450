import React from 'react';

import LocalImage from '../../../../components/LocalImage';
import { getMetafield } from '../../../../utils/helpers';

import './ProductOneTestimonial.scss';

const defaultTestimonial = `"I am always satisfied with the Miracle System, the kit is easy to use
		every time and provides shine and super soft hair plus it covers all my
		white hairs

				😌
		
		. The color is what I need and absolutely fabulous for me. Josh Wood
		never disappoints!! Thank you so much."`;

const defaultTestimonialName = 'Alexandra N.';

const ProductOneTestimonial = ({
	isMobile = false,
	isHomePage = false,
	product = undefined,
}) => {
	const testimonial = product
		? getMetafield(product, 'customer_review')
		: undefined;
	const testimonialName = product
		? getMetafield(product, 'customer_review_name')
		: undefined;
	return (
		<article className={`testimonial ${isHomePage && 'spacing-homepage'}`}>
			{isMobile && (
				<div className='testimonial__rating' aria-label="5 out of 5 stars">
					<span className='testimonial__star' aria-hidden="true">★★★★★</span>
				</div>
			)}
			<blockquote className='testimonial__quote'>
				{testimonial ?? defaultTestimonial}
			</blockquote>
			<footer className='testimonial__author'>
				{!isMobile && (
					<LocalImage
						image='women-review.png'
						alt={`Profile photo of ${testimonialName ?? defaultTestimonialName}`}
					/>
				)}
				<div className='testimonial__details'>
					<cite className='testimonial__name bold'>
						{testimonialName ?? defaultTestimonialName}&nbsp;
					</cite>
					<span className='testimonial__verified'>
						Verified Buyer
					</span>
					{!isMobile && (
						<div className='testimonial__rating' aria-label="5 out of 5 stars">
							<span className='testimonial__star' aria-hidden="true">★★★★★</span>
						</div>
					)}
				</div>
			</footer>
		</article>
	);
};

export default ProductOneTestimonial;
