/* eslint-disable */
import { v4 as uuid } from 'uuid';

import applyDiscount from './cart/applyDiscount';
import axios from 'axios';
import { submitErrorToSlack } from './slackMessage';
import { getSellingPlanGroupsIds } from '../helpers/getSellingPlanGroupsIds';
import { getSellingPlanGroupsByIds } from './queries';
import HttpError from './httpError';

const shopifyStoreUrl = process.env.SHOPIFY_STORE_URL;
const shopifyCheckoutUrl = process.env.SHOPIFY_CHECKOUT_URL;

export const isBrowser = typeof window !== 'undefined';

export const getPrice = (price, cart, digit = 0) => {
	let currencyCode = 'GBP';

	if (
		cart?.cost?.totalAmount?.currencyCode &&
		cart.cost.totalAmount.currencyCode !== 'XXX'
	) {
		currencyCode = cart.cost.totalAmount.currencyCode;
	}

	return Intl.NumberFormat('en-US', {
		currency: currencyCode,
		style: 'currency',
		minimumFractionDigits: digit,
		maximumFractionDigits: digit,
	}).format(parseFloat(price || 0));
};

export const partIsNumber = (string, pos) =>
	string.split('-')[pos] &&
	typeof Number(string.split('-')[pos]) === 'number' &&
	!Number.isNaN(Number(string.split('-')[pos]));

export const createArray = (string) =>
	(string || '')
		.replace('"', '')
		.replace('[', '')
		.replace(']', '')
		.replace('"', '')
		.split('|');

export const checkOption = (product, name) =>
	(product.options || []).some((v) => v.name.toLowerCase() === name);

export const getMetafield = (product, key) => {
	const field =
		Array.isArray(product?.metafields) &&
		product?.metafields.filter((metafield) => metafield?.key === key)[0];
	if (field) {
		return field.value
			.replace('"', '')
			.replace('[', '')
			.replace(']', '')
			.replace('"', '');
	}
	return null;
};

export const getMetafieldParse = (product, key) => {
	const field =
		Array.isArray(product?.metafields) &&
		product?.metafields.filter((metafield) => metafield?.key === key)[0];
	if (field) {
		return JSON.parse(field.value);
	}
	return null;
};

export const extractInnerPortionText = (text, removeLength) => {
	const areInvalidParameters =
		!text ||
		typeof text !== 'string' ||
		typeof removeLength !== 'number' ||
		text.length <= removeLength;

	if (areInvalidParameters) return;

	return text.slice(removeLength, text.length - removeLength);
};

export const getMetafieldsObject = (metafields) => {
	if (
		!metafields ||
		metafields?.length === 0 ||
		typeof metafields !== 'object'
	)
		return null;

	let metafieldsObject = {};
	for (const metafield of metafields) {
		if (metafield) {
			metafieldsObject = {
				...metafieldsObject,
				[metafield.key]: metafield.value,
			};
		}
	}

	return metafieldsObject;
};

export const convertArticleDate = (articleDate) => {
	if (
		!articleDate ||
		typeof articleDate !== 'string' ||
		!articleDate.includes('-')
	)
		return;

	const options = { year: 'numeric', month: 'long', day: 'numeric' };

	if (articleDate.includes('T')) {
		const date = new Date(articleDate);
		const formattedDate = date.toLocaleDateString('en-US', options);
		return formattedDate;
	}

	const [year, month, day] = articleDate.split('-');
	const numericMonth = parseInt(month) - 1;
	const dateObject = new Date(year, numericMonth, day);
	const formattedDateString = dateObject.toLocaleDateString('en-US', options);
	return formattedDateString;
};

export const getMetafieldRaw = (product, key) => {
	const field = (product?.metafields || []).filter((mf) => mf.key === key)[0];
	return field?.value ?? null;
};

export const delShopSymbols = (text) => {
	if (typeof text !== 'string') return '';

	return text
		?.replace(/\["/g, '')
		?.replace(/"\]/g, '')
		?.replace(/\\r\\n/g, '\n')
		?.replace(/\\/g, '');
};

export const getVideoId = (link) =>
	(link || '').indexOf('https://youtu.be/') !== -1
		? (link || '').replace('https://youtu.be/', '')
		: (link || '').replace('https://www.youtube.com/watch?v=', '');

export const removeArrayItem = (arr, value) => {
	let b = '';
	for (b in arr) {
		if (arr[b] === value) {
			arr.splice(b, 1);
			break;
		}
	}
	return arr;
};

export const getSortedKeys = (keys = [], array) =>
	keys?.length > 0
		? keys.forEach((key) => {
				const splitKey = key.split('-');
				// eslint-disable-next-line default-case
				switch (splitKey[0]) {
					case 'price':
						array.sort((a, b) =>
							splitKey[1] === 'asc'
								? parseFloat(a.variants[0].price) -
								  parseFloat(b.variants[0].price)
								: parseFloat(b.variants[0].price) -
								  parseFloat(a.variants[0].price)
						);
						break;
				}
		  })
		: array;

const cleanString = (input) => {
	return input?.toLowerCase().replace(/[^a-z\s]/g, '') || '';
};

const hasMatchingTerm = (input, tagsSet) => {
	const inputWords = input.split(' ');
	return inputWords.some((word) => tagsSet.has(word));
};

export const getFilteredTags = (tags, array, contains = false) => {
	if (!tags.length) return array;

	let filteredArray = [];
	const tagsSet = new Set(
		tags.flatMap((tag) => tag.toLowerCase().split(' '))
	);

	const filteredWithProductType = (array || []).filter((item) => {
		const cleanedProductType = cleanString(item?.productType);
		return hasMatchingTerm(cleanedProductType, tagsSet);
	});

	const filteredWithName = (array || []).filter((item) => {
		const cleanedProductName = cleanString(item?.title);
		return hasMatchingTerm(cleanedProductName, tagsSet);
	});

	const filteredWithTags = (array || []).filter((item) =>
		(item.node || item).tags.some((tag) =>
			contains
				? tags.some((t) => tag.toLowerCase().includes(t.toLowerCase()))
				: tags.includes(tag.toLowerCase())
		)
	);

	filteredArray = [
		...new Set([
			...filteredWithProductType,
			...filteredWithName,
			...filteredWithTags,
		]),
	];

	(array || []).forEach((el, index) => {
		if ((el.node || el).tags[0] === 'not_product') {
			index < filteredArray.length
				? (filteredArray = [
						...filteredArray.slice(0, index),
						el,
						...filteredArray.slice(index),
				  ])
				: (filteredArray = [...filteredArray, el]);
		}
	});

	return filteredArray;
};

export const isGif = (extension) => extension === 'gif';

export const filteredInstagramPosts = (tags, array) =>
	tags.length > 0
		? (array || []).filter((item) =>
				tags.some((tag) =>
					item.edge_media_to_caption.edges[0].node.text.includes(
						`#${tag}`
					)
				)
		  )
		: array;

export const getQuery = (q) =>
	isBrowser
		? (window.location.search.match(new RegExp(`[?&]${q}=([^&]+)`)) || [
				0,
				null,
		  ])[1]
		: '';

export const getSearchArray = (arr, query) =>
	(arr || []).filter((item) => item.node.title.toLowerCase().includes(query));

export const getSearchArrayWithVariants = (arr, query) => {
	const TITLE_MATCHES = (arr || []).filter((item) =>
		item.node.title.toLowerCase().includes(query)
	);
	let allFilteredProducts = [],
		allFilteredVariants = [];

	(arr || []).forEach((item) => {
		if (
			item.node.variants.some((variant) =>
				variant.title.toLowerCase().includes(query)
			)
		) {
			allFilteredVariants = [
				...allFilteredVariants,
				...item.node.variants.map((variant) => ({
					node: {
						...item.node,
						...variant,
						title: `${item.node.title} - ${variant.title}`,
					},
				})),
			];
		}
	});

	allFilteredProducts = [...TITLE_MATCHES, ...allFilteredVariants];

	return allFilteredProducts;
};

export const isShadeShot = (product) =>
	(product.tags || []).includes('shadeshot') ||
	(product.tags || []).includes('shade shot');

export const getCollectionsMenu = (arr, menu) =>
	((arr || []) && (menu || []))
		.filter((item) => menu.includes(item.node.handle))
		.sort(
			(a, b) => menu.indexOf(a.node.handle) - menu.indexOf(b.node.handle)
		);

export const getFeaturedProductsHomePage = (products, handles) =>
	(products || []).filter((item) =>
		(handles || []).includes(item.node.handle)
	);

export const decodeBase64 = (s, shouldReplace = true) => {
	if (s.includes('gid://')) {
		return s;
	}
	const e = {};
	let i,
		b = 0,
		c,
		x,
		l = 0,
		a,
		r = '';
	const w = String.fromCharCode,
		L = (s || '').length,
		A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
	for (i = 0; i < 64; i++) {
		e[A.charAt(i)] = i;
	}
	for (x = 0; x < L; x++) {
		c = e[s.charAt(x)];
		b = (b << 6) + c;
		l += 6;
		while (l >= 8) {
			((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
		}
	}
	if (shouldReplace) {
		return r
			.replace('gid://shopify/ProductVariant/', '')
			.replace('gid://shopify/Product/', '');
	}
	return r;
};

export const setScript = (url, id, type) => {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.id = id;
	type === 'defer' ? (script.defer = true) : (script.async = true);
	script.src = url;
	document.getElementsByTagName('head')[0].appendChild(script);
};

export const getValByKey = (key, obj) => {
	if (obj.hasOwnProperty(key)) {
		return obj[key];
	}
	return {};
};

export const getDiscountPrice = (original, discountPercentage) => {
	let discountPrice = original - original * (discountPercentage / 100);
	discountPrice = discountPrice.toFixed(2);
	discountPrice =
		discountPrice.toString().split('.')[1] === '00'
			? parseInt(discountPrice)
			: discountPrice;

	return discountPrice;
};

export const gemMiracleTone = (name) => {
	const row = name.toLowerCase().split('+')[1].trim(),
		colourName = name.toLowerCase().replace(/[^\d.]/g, ''),
		toneName = row.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
	let toneImg = row.replace(' ', '-');
	toneImg = `${toneImg}`;
	return { toneName, toneImg, colourName };
};

export const isAvailable = (product) =>
	(product?.variants || []).some((item) => !!item.availableForSale);

export const isActive = (name, type, tones) => {
	switch (name) {
		case 'all':
			return type === '' && !tones.length;
		case 'gloss':
		case 'glaze':
			return type === name;
		case 'light hair':
		case 'dark hair':
		case 'red hair':
			return tones.includes(name);
		default:
			return false;
	}
};

export const filterByTones = (products, tones) => {
	const result = [];
	for (const tone of tones) {
		for (const product of products) {
			if (product.tags.includes(tone)) {
				result.push(product);
			}
		}
	}
	return result;
};

export const formatNumber = (num, toFixedNum = 1) => {
	if (Math.abs(num) >= 1000000) return `${(num / 1e6).toFixed(toFixedNum)}M`;
	if (Math.abs(num) >= 1000) return `${(num / 1e3).toFixed(toFixedNum)}K`;
	return num;
};

export const goToCheckout = async (cart) => {
	if (!isBrowser) {
		console.error(
			'This function can only be run in a browser environment - goToCheckout'
		);
		return;
	}

	const code = isBrowser && sessionStorage.getItem('code');
	const cartId = cart.id;

	if (code) {
		if (
			(code === 'MIRACLE50' && +cart.cost.totalAmount.amount >= 50) ||
			(code === 'MIRACLE45' && +cart.cost.totalAmount.amount >= 45)
		) {
			await applyDiscount(cartId, code);
			await makeRedirect(cart);
			return;
		}

		if (code === 'MIRACLE-SHOT') {
			let isValid = false;
			const itemsShouldBeAdded =
				sessionStorage.getItem('itemsShouldBeAdded');
			for (const item of cart.lines.edges) {
				if (
					itemsShouldBeAdded.includes(atob(item.node.merchandise.id))
				) {
					isValid = true;
					break;
				}
			}
			if (isValid) {
				await applyDiscount(cartId, code);
				await makeRedirect(cart);
				return;
			}
		}

		if (
			code === 'QUIZCOMPLETE15OFF' &&
			+cart.cost.totalAmount.amount >= 60
		) {
			const itemsQuizShouldBeAdded = JSON.parse(
				sessionStorage.getItem('itemsQuizShouldBeAdded')
			);

			const cartLineIds = cart.lines.edges.map(
				(item) =>
					+item.node.merchandise.id.replace(
						'gid://shopify/ProductVariant/',
						''
					)
			);

			const allItemsAreInCart = itemsQuizShouldBeAdded.every((item) =>
				cartLineIds.includes(item.variantId)
			);

			if (allItemsAreInCart) {
				await applyDiscount(cartId, code);
				await makeRedirect(cart);
				return;
			}
		}
	}
	if (!cart || !cart.checkoutUrl) {
		console.error(
			'Invalid cart object or missing checkoutUrl in goToCheckout.'
		);
		return;
	}

	await makeRedirect(cart);
};

const makeRedirect = async (cart) => {
	if (!shopifyStoreUrl || !shopifyCheckoutUrl) {
		console.error(
			'Missing environment variables for Shopify URLs in makeRedirect.'
		);
		return;
	}

	const customerEmail = sessionStorage.getItem('userEmail');
	const checkoutURL = cart.checkoutUrl;
	const checkoutUrlWithoutPrefix = checkoutURL.replace(
		'https://checkout.joshwoodcolour.com',
		''
	);
	const newCheckoutURL = checkoutURL.replace(
		shopifyStoreUrl,
		shopifyCheckoutUrl
	);

	if (!customerEmail) {
		window.location.href = newCheckoutURL;
		return;
	}

	const multipassUrl = '/api/multipassLogin';
	try {
		const multipassResponse = await axios.post(multipassUrl, {
			customerEmail,
			redirectUrl: checkoutUrlWithoutPrefix,
		});
		const multipassRedirectURL =
			multipassResponse?.data?.redirectUrlWithAuthentication;
		window.location.href = multipassRedirectURL;
	} catch (error) {
		window.location.href = newCheckoutURL;
	}
};

export const getUrlQueries = (name) => {
	if (isBrowser) {
		let hash;
		const vars = [],
			hashes = window.location.href
				.slice(window.location.href.indexOf('?') + 1)
				.split('&');
		for (let i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			vars.push(hash[0]);
			vars[hash[0]] = hash[1];
		}
		return vars[name];
	}
};

export const getProduct = async (variantId) => {
	const url = `/api/getProduct?variantId=${variantId}`;
	try {
		const response = await axios.get(url);
		if (!response.data) {
			throw new Error('API request failed');
		}
		return response.data;
	} catch (error) {
		const status = error?.response?.status;
		if (status >= 500) {
			submitErrorToSlack(url, error, 'GET');
		}
		console.error(error);
		return error;
	}
};

export const getDiscount = async (priceRuleId) => {
	try {
		const response = await axios.get(
			`/api/getDiscount?priceRuleId=${priceRuleId}`
		);
		if (!response.data) {
			throw new Error('API request failed');
		}
		const data = response.data;
		if (data) return data;
	} catch (error) {
		const status = error?.response?.status;
		if (status >= 500) {
			submitErrorToSlack(url, error, 'GET');
		}
		console.error(error);
		return error;
	}
};

export const klaviyoLoad = () => {
	try {
		const siteId = process.env.KLAVIYO_ID;
		const script = document.createElement('script');
		script.async = true;
		script.src =
			`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${siteId}`.replace(
				/\s+/g,
				''
			);

		document.head.append(script);
	} catch (_a) {
		console.error('Error initializing klaviyo');
	}
};

export const getGlobalMetafields = async () => {
	const url = '/api/getShopMetafields';
	try {
		const response = await axios.get(url);
		if (!response.data) {
			throw new Error('API request failed');
		}
		const { metafields } = response.data;

		return metafields;
	} catch (error) {
		const status = error?.response?.status;
		if (status >= 500) {
			submitErrorToSlack(url, error, 'GET');
		}
		console.error(error);
		return error;
	}
};

export const getSellingPlanGroups = async () => {
	const sellingPlanGroupsIds = await getSellingPlanGroupsIds();

	const url = `https://${process.env.SHOP_NAME}.myshopify.com/admin/api/2024-04/graphql.json`;
	const headers = {
		'Content-Type': 'application/json',
		'X-Shopify-Access-Token': process.env.SHOPIFY_SHOP_PASSWORD,
	};
	const body = JSON.stringify({
		query: getSellingPlanGroupsByIds,
		variables: { ids: sellingPlanGroupsIds },
	});

	try {
		const response = await fetch(url, {
			method: 'POST',
			headers,
			body,
			redirect: 'follow',
		});

		const { data } = await response.json();

		return data;
	} catch (error) {
		console.error('Error fetching selling plan groups:', error);
	}
};

export const getGlobalMetafield = (field, allMeta) => {
	if (field) {
		if (Array.isArray(field) && Array.isArray(field).length) {
			return (allMeta || []).filter((el) => allMeta.includes(el.key));
		}
		const findField = (allMeta || []).find((el) => el.key === field);
		return findField || {};
	}
	return allMeta;
};

export const insertToArray = (arr, index, newItem) => [
	...arr.slice(0, index),
	newItem,
	...arr.slice(index),
];

export const mergeArrays = (array) => [...new Set(array.flat(1))];

/**
 * @param {Object} product
 * @param {Array} product.variants
 * */
export const getMinPriceVariant = (product) => {
	if (!product) return;

	if (!product.variants && product.variant) return product.variant;

	return product.variants.length === 1
		? product.variants[0]
		: product.variants
				// if Shopify change the API and object structure for 'product'
				.sort((a, b) => {
					if (a?.storefrontId ?? a?.id > b?.storefrontId ?? b?.id)
						return 1;
					if (a?.storefrontId ?? a?.id < b?.storefrontId ?? b?.id)
						return -1;
					return 0;
				})
				.sort((a, b) => a?.price - b?.price)[0];
};

export const sendAddToBagEvent = async (
	variant,
	addedProduct,
	quantity,
	cart
) => {
	const prevLocation = window.prevPath;
	const urlPath = window.location.pathname;
	const isCollection = urlPath.includes('collections');
	const list = isCollection ? urlPath : prevLocation ?? urlPath;
	try {
		if (!addedProduct) {
			const {
				data: {
					productVariant: { product },
				},
			} = await getProduct(
				variant.merchandise.id.replace('gid://', 'gid')
			);

			window['dataLayer'].push({ ecommerce: null });
			window['dataLayer'].push({
				event: 'dl_add_to_cart',
				event_id: uuid(),
				ecommerce: {
					currencyCode: variant.merchandise.priceV2.currencyCode,
					add: {
						actionField: {
							list: list,
						},
						products: [
							{
								name: product.title,
								id: variant.merchandise.sku,
								product_id: product.storefrontId?.replace(
									'gid://shopify/Product/',
									''
								),
								variant_id: decodeBase64(
									variant.merchandise.id
								)?.replace('gid://shopify/ProductVariant/', ''),
								image: variant.merchandise.image.src,
								price: +variant.merchandise.priceV2.amount,
								brand: 'JWC',
								variant: variant.merchandise.title,
								category: product.productType,
								quantity: quantity,
								list: list,
							},
						],
					},
				},
			});
		} else {
			window['dataLayer'].push({ ecommerce: null });
			window['dataLayer'].push({
				event: 'dl_add_to_cart',
				event_id: uuid(),
				ecommerce: {
					currencyCode:
						addedProduct.priceRangeV2.minVariantPrice.currencyCode,
					add: {
						actionField: {
							list: window.location.pathname,
						},
						products: [
							{
								name: addedProduct.title,
								id: variant.merchandise.sku,
								product_id: decodeBase64(
									addedProduct.storefrontId,
									false
								)?.replace('gid://shopify/Product/', ''),
								variant_id: decodeBase64(
									variant.merchandise.id
								)?.replace('gid://shopify/ProductVariant/', ''),
								image: variant.merchandise.image.src,
								price: +variant.merchandise.priceV2.amount,
								brand: 'JWC',
								variant: variant.merchandise.title,
								category: addedProduct.productType,
								quantity: quantity,
								list: list,
							},
						],
					},
				},
			});
		}
	} catch (error) {
		const product = {
			id: addedProduct.id,
			handle: addedProduct.handle,
			storefrontId: addedProduct.storefrontId,
			title: addedProduct.title,
		};
		const betterCartInfo = cart
			.map((item) => JSON.stringify(item))
			.join('\n\n');
		submitErrorToSlack(
			`Failed to add product to dataLayer

			Product: ${JSON.stringify(product)}

			Variant: ${JSON.stringify(variant)}

			Cart: ${betterCartInfo}
			`,
			error,
			'POST'
		);
	}
};

export const sendRemoveCartEvent = (item, title) => {
	window['dataLayer'].push({ ecommerce: null });
	window['dataLayer'].push({
		event: 'dl_remove_from_cart',
		event_id: uuid(),
		ecommerce: {
			currencyCode: item.merchandise.priceV2.currencyCode,
			remove: {
				actionField: { list: window.location.pathname },
				products: [
					{
						name: title,
						id: item.merchandise.sku,
						product_id: decodeBase64(item.id),
						variant_id: decodeBase64(item.merchandise.id),
						image: item.merchandise.image.src,
						price: +item.merchandise.priceV2.amount,
						brand: 'JWC',
						variant: item.merchandise.title,
						quantity: [],
						list: window.location.pathname,
					},
				],
			},
		},
	});
};

export const sendPlpOrSearchEvent = (cart, products, eventName) => {
	const allProducts = (products || []).map((product, idx) => {
		if (eventName === 'dl_search_results') {
			product = product.node;
		}
		return {
			position: idx,
			id: getMinPriceVariant(product).sku,
			name: product.title,
			product_id: decodeBase64(product.storefrontId),
			variant_id: decodeBase64(getMinPriceVariant(product).storefrontId),
			price: +product.priceRangeV2.minVariantPrice?.amount,
			brand: 'JWC',
			list: window.location.pathname,
		};
	});

	window['dataLayer'].push({ ecommerce: null });
	window['dataLayer'].push({
		event: eventName,
		event_id: uuid(), // unique uuid for FB conversion API
		ecommerce: {
			currencyCode:
				products[0]?.priceRangeV2?.maxVariantPrice?.currencyCode,
			impressions: allProducts,
		},
	});
};

export const sendProductClickEvent = (product) => {
	const prevLocation = window.prevPath;
	const urlPath = window.location.pathname;
	const isCollection = urlPath.includes('collections');
	const list = isCollection ? urlPath : prevLocation ?? urlPath;

	let category = '';
	if (product.productType) {
		category = product.productType;
	} else if (isCollection) {
		category = urlPath.replace('/collections/', '').split('-').join(' ');
	}

	const clickedProduct = {
		name: product.title,
		id: getMinPriceVariant(product).sku,
		product_id: decodeBase64(product.storefrontId),
		variant_id: decodeBase64(getMinPriceVariant(product).storefrontId),
		price: +getMinPriceVariant(product).price,
		brand: 'JWC',
		position: 0,
		category,
		list: urlPath,
	};

	window['dataLayer'].push({ ecommerce: null });
	window['dataLayer'].push({
		event: 'dl_select_item',
		event_id: uuid(),
		ecommerce: {
			currencyCode: product.priceRangeV2?.maxVariantPrice?.currencyCode,
			click: {
				actionField: { list: list },
				products: clickedProduct,
			},
		},
	});
};

export const sendViewItemEvent = (product) => {
	const prevLocation = window.prevPath;
	const urlPath = window.location.pathname;
	const isCollection = urlPath.includes('collections');
	const list = isCollection ? urlPath : prevLocation ?? urlPath;

	window['dataLayer']?.push({ ecommerce: null });
	window['dataLayer']?.push({
		event: 'dl_view_item',
		event_id: uuid(),
		ecommerce: {
			currencyCode: product.priceRangeV2.minVariantPrice.currencyCode,
			detail: {
				actionField: {
					list: window.location.pathname,
				},
				products: [
					{
						name: product.title,
						id: getMinPriceVariant(product).sku,
						product_id: decodeBase64(product.storefrontId)?.replace(
							'gid://shopify/Product/',
							''
						),
						variant_id: decodeBase64(
							getMinPriceVariant(product).storefrontId
						)?.replace('gid://shopify/ProductVariant/', ''),
						image: product.media[0].preview.image.originalSrc,
						price: +product.priceRangeV2.minVariantPrice.amount,
						brand: 'JWC',
						variant: getMinPriceVariant(product).title,
						category: product.productType,
						inventory: product.totalInventory,
						list: list,
					},
				],
			},
		},
	});
};

export const getCustomerIdByAccessToken = async (accessToken) => {
	if (!accessToken) return null;
	const customerQuery = {
		query: `
            query($token: String!){
                customer(customerAccessToken: $token) {
                    id
                }
            }
        `,
		variables: {
			token: accessToken,
		},
	};

	const reqOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-Shopify-Storefront-Access-Token':
				process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
		},
		body: JSON.stringify(customerQuery),
		redirect: 'follow',
	};

	const url = `https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`;
	try {
		const response = await axios.post(url, reqOptions);
		if (!response.data) {
			throw new Error('API request failed');
		}

		const { data } = response.data;

		return data.customer.id.replace('gid://shopify/Customer/', '');
	} catch (error) {
		const status = error?.response?.status;
		if (status >= 500) {
			submitErrorToSlack(url, error, 'POST');
		}
		console.error(error);
		return error;
	}
};

export const capitalize = (word) => {
	const loweredCase = word.toLowerCase();
	return word[0].toUpperCase() + loweredCase.slice(1);
};

export const getSellingPlanGroupOptions = (arr) => {
	const options = [];
	arr.forEach((item) => {
		options.push(item.node.name.replace('Delivery every ', ''));
	});
	return options;
};

export const getSellingPlanGroupDefaultValue = (arr) => {
	const index = arr.findIndex((item) => item.substring(0, 1) === '4');
	return index > -1 ? index : 0;
};

export const matchImgExtension = (targetExtension, filePath) => {
	if (typeof targetExtension !== 'string' || typeof filePath !== 'string') {
		return false;
	}

	const extractedExtension = filePath.split('.').pop().toLowerCase();
	return targetExtension.toLowerCase() === extractedExtension;
};

export const customerFetch = async (customerId) => {
	const url = '/api/request';
	const header = {
		'Content-Type': 'application/json',
	};

	try {
		const response = await axios.post(url, customerId, {
			headers: header,
		});

		if (!response.data) {
			throw new Error('API request failed');
		}

		const { customer } = response.data;

		return customer;
	} catch (error) {
		const status = error?.response?.status;
		if (status >= 500) {
			submitErrorToSlack(url, error, 'POST');
		}
		console.error(error);
		return error;
	}
};

export const formatDate = (date) => {
	const day = date.getDate();
	const month = date.toLocaleString('en-GB', { month: 'long' });
	const suffix =
		day > 3 && day < 21 ? 'th' : ['st', 'nd', 'rd'][(day % 10) - 1] || 'th';
	return `${day}${suffix} ${month}`;
};

export async function generateCodeVerifier() {
	const rando = generateRandomCode();
	return base64UrlEncode(rando);
}

export async function generateCodeChallenge(codeVerifier) {
	const digestOp = await crypto.subtle.digest(
		{ name: 'SHA-256' },
		new TextEncoder().encode(codeVerifier)
	);
	const hash = convertBufferToString(digestOp);
	return base64UrlEncode(hash);
}

function generateRandomCode() {
	const array = new Uint8Array(32);
	crypto.getRandomValues(array);
	return String.fromCharCode.apply(null, Array.from(array));
}

function base64UrlEncode(str) {
	const base64 = btoa(str);
	return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

function convertBufferToString(hash) {
	const uintArray = new Uint8Array(hash);
	const numberArray = Array.from(uintArray);
	return String.fromCharCode(...numberArray);
}

export async function generateState() {
	const timestamp = Date.now().toString();
	const randomString = Math.random().toString(36).substring(2);
	return timestamp + randomString;
}