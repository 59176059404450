import React, { useContext, useEffect, useState, lazy, Suspense } from 'react';

import { useWindowWidth } from '../../../utils/hooks';
import './ProductFirstScreen.scss';
import { StoreContext } from '../../../context/store-context';
import Loader from '../../../components/Loader';

const ProductPhotosSlider = lazy(() => import('./ProductPhotosSlider'));
const ProductInfoContainer = lazy(() => import('./ProductInfo'));
const ProductInfoCollection = lazy(() => import('./ProductInfo/ProductInfoCollection'));

const ProductFirstScreen = ({
	product: currentItem,
	cart,
	collections,
	addToCart,
	customPreviewImage,
	allBaseColours,
	productsList,
	shadeShotsProducts,
	isPermanentColour,
	activeVariant,
	setActiveVariant,
	rating,
	scrollToReviews,
	sellingPlanGroup,
	MiracleSystemSellingPlanGroup
}) => {
	const { currentPermanent } = useContext(StoreContext);
	const windowWidth = useWindowWidth();
	const [withShadeShot, setWithShadeShot] = useState([]);
	const [shots, setShots] = useState([]);
	const [product, setProduct] = useState(currentPermanent || currentItem);
	const [miracleSystem, setMiracleSystem] = useState({});

	useEffect(() => {
		if (currentPermanent) {
			setProduct(currentPermanent);
		}
	}, [currentPermanent]);

	useEffect(() => {
		let shadeShots = [];
		const { title } = product,
			colourNumber = title.split('')[2],
			necessaryProductPlusShadeShot =
				productsList &&
				productsList
					.filter(
						(item) =>
							item.title.includes('Shot') &&
							item.handle.includes('permanent')
					)
					.filter(
						(item) =>
							typeof +item.handle.split('-')[2] === 'number' &&
							item.handle.split('-')[2]
					)
					.filter((item) => item.title.includes(colourNumber));

		if (
			isPermanentColour &&
			necessaryProductPlusShadeShot &&
			necessaryProductPlusShadeShot.length
		) {
			shadeShots = necessaryProductPlusShadeShot[0].variants
				.filter((item) => !item.title.includes('Shot'))
				.map(({ title }) => {
					const variantId = shadeShotsProducts.find((item) =>
						item.title.includes(title)
					);
					return { title, variantId };
				});
			setShots(shadeShots);
		}
		setWithShadeShot(necessaryProductPlusShadeShot);
		const miracleSystem =
			productsList &&
			productsList.find((item) => item.handle === 'miracle-system');
		setMiracleSystem(miracleSystem);
	}, []);

	return (
		<section className='pdp-main'>
			<div className='container flex--desktop between'>
				<Suspense fallback={<Loader />}>
					<ProductPhotosSlider
						{...{
							allBaseColours,
							shadeShotsProducts,
							withShadeShot,
							miracleSystem,
							product,
							customPreviewImage,
							productsList,
							shots,
						}}
					/>
				</Suspense>
				{!isPermanentColour ? (
					<Suspense fallback={<Loader />}>
						<ProductInfoContainer
							{...{
								product,
								collections,
								cart,
								addToCart,
								windowWidth,
								allBaseColours,
								productsList,
								shadeShotsProducts,
								withShadeShot,
								shots,
								activeVariant,
								setActiveVariant,
								rating,
								scrollToReviews,
								sellingPlanGroup,
								miracleSystem,
								MiracleSystemSellingPlanGroup
							}}
						/>
					</Suspense>
				) : (
					<Suspense fallback={<Loader />}>
						<ProductInfoCollection
							{...{
								product,
								collections,
								cart,
								addToCart,
								windowWidth,
								productsList,
								allBaseColours,
								shadeShotsProducts,
								shots,
								activeVariant,
								setActiveVariant,
								rating,
								sellingPlanGroup,
							}}
						/>
					</Suspense>
				)}
			</div>
		</section>
	);
};

export default ProductFirstScreen;
