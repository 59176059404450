import React, { lazy, useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../../context/store-context';
import { getMetafield, isAvailable, isShadeShot } from '../../../utils/helpers';

import { getSellingPlanGroup } from '../../../utils/node-helpers';

const ProductItem = lazy(() => import('./ProductItem'));

const ProductItemContainer = ({ product, cart, allSellingPlanGroups }) => {
	const { subscriptionsEnabled } = useContext(StoreContext);
	const [sellingPlanGroup, setSellingPlanGroup] = useState(
		subscriptionsEnabled &&
		allSellingPlanGroups &&
		getSellingPlanGroup(
			product.storefrontId,
			allSellingPlanGroups.nodes
		)
	);
	const [available, setAvailable] = useState(null);
	const isRecommended = !!getMetafield(product, 'set_as_recommended');
	const isHide = getMetafield(product, 'is_hide');
	const isShot = isShadeShot(product);

	useEffect(() => {
		if (subscriptionsEnabled && allSellingPlanGroups) {
			setSellingPlanGroup(
				getSellingPlanGroup(
					product.storefrontId,
					allSellingPlanGroups.nodes
				)
			);
		}
	}, [allSellingPlanGroups]);

	useEffect(() => {
		setAvailable(isAvailable(product));
	}, []);

	return (
		<>
			{!product.title.includes('z_DISCONTINUED') &&
				!isHide &&
				product.handle !== 'permanent-colour-8-5' &&
				!product.handle.includes('_b') &&
				product.handle !== 'miracle-shot' &&
				product.handle !== 'chestnut-brunette-miracle-shot' &&
				!product.handle.includes('system-old') ? (
				<ProductItem
					available={available}
					isShot={isShot}
					cart={cart}
					isRecommended={isRecommended}
					product={product}
					sellingPlanGroup={sellingPlanGroup}
					subscriptionsEnabled={subscriptionsEnabled}
				/>
			) : null}
		</>
	);
};

export default ProductItemContainer;
