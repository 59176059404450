// External
import React, { useContext, useEffect, useState } from 'react';

// Internal
import FilterForm from './FilterForm';
import filterProducts from '../../helpers/productsFilter';
import { StoreContext } from '../../context/store-context';
import useCloseWhenClickOutside from '../../hooks/useCloseWhenClickOutside';

import './FilterAndSort.scss';

const FilterAndSort = ({ allProducts, setFilteredProducts }) => {
	const { yotpoRatings } = useContext(StoreContext);
	const [openCloseFilter, setOpenCloseFilter] =
		useCloseWhenClickOutside(false);
	const [activeFilters, setActiveFilters] = useState({
		price: '',
		rating: '',
		concerns: '',
		type: '',
		colour: '',
	});

	const activeFiltersValues = Object.values(activeFilters).filter(
		(value) => value !== ''
	);

	useEffect(() => {
		setFilteredProducts(
			filterProducts(allProducts, activeFilters, yotpoRatings)
		);
	}, [activeFilters]);

	return (
		<div
			className='filter-bar'
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			{openCloseFilter && (
				<FilterForm
					activeFilters={activeFilters}
					setActiveFilters={setActiveFilters}
					onClose={() => setOpenCloseFilter(false)}
				/>
			)}
		</div>
	);
};

export default FilterAndSort;
