import React from 'react';

const ExpertsHeroBanner = () => (
  <section 
    className='experts-bg'
    role="banner" 
    aria-label="Experts page hero banner"
  />
);

export default ExpertsHeroBanner;
