import React from 'react';
import { useWindowWidth } from '../../../utils/hooks';

import Vimeo from '../../Video/vimeo';

const VideoJosh = ({ videoId, isHomepage = false }) => {
	const windowWidth = useWindowWidth();

	const isMobile = windowWidth < 800;
	return (
		<div className='pdp-talks-v2'>
			<div className='container flex'>
				<div className='pdp-talks-v2__content'>
					<div className='pdp-talks-v2__content__text'>
						{isMobile && isHomepage && (
							<div className='pdp-talks-v2__content__video'>
								{videoId ? (
									<Vimeo
										videoId={videoId}
										videoTitle='Meet Josh'
										initialLoad
									/>
								) : null}
							</div>
						)}
						<h2
							className={`pdp-talks-v2__content__title bold ${
								isHomepage && 'homepage-video'
							}`}
						>
							Meet Josh
						</h2>
						<p
							className={`pdp-talks-v2__content__desc ${
								isHomepage && 'homepage-video'
							}`}
						>
							The colour expert who’s been trusted by some of the
							world’s biggest names for over 30 years.
						</p>
						<p
							className={`pdp-talks-v2__content__desc ${
								isHomepage && 'homepage-video'
							}`}
						>
							Josh has worked his magic on red carpets,
							photoshoots, and fashion shows, but what he loves
							most is helping people feel amazing wherever they
							are.
						</p>
						<p
							className={`pdp-talks-v2__content__desc ${
								isHomepage && 'homepage-video'
							}`}
						>
							That’s why he created his own Miracle range of
							products, clinically proven to reduce breakage by
							62%. He’s taken everything he knows about stunning,
							natural-looking colour and made it easy for anyone
							to use, even if you’re not a pro.
						</p>

						{isMobile && !isHomepage && (
							<div className='pdp-talks-v2__content__video'>
								{videoId ? (
									<Vimeo
										videoId={videoId}
										videoTitle='Meet Josh'
										initialLoad
									/>
								) : null}
							</div>
						)}
					</div>
					{!isMobile && (
						<div className='pdp-talks-v2__content__video'>
							{videoId ? (
								<Vimeo
									videoId={videoId}
									videoTitle='Meet Josh'
									initialLoad
								/>
							) : null}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default VideoJosh;
