// External
import React, { useContext, Suspense } from 'react';

// Internal
import ExpertsGridItem from './ExpertsGridItem/ExpertsGridItem';
import Loader from '../../../components/Loader/index';

import { StoreContext } from '../../../context/store-context';
import { getFilteredTags } from '../../../utils/helpers';

import './ExpertsGrid.scss';

const ExpertsGrid = ({ experts }) => {
	const { filterTags } = useContext(StoreContext);
	const filteredList = getFilteredTags(filterTags, experts, true);

	return (
		<Suspense fallback={<Loader />}>
			<ul className='experts-grid'>
				{Array.isArray(filteredList) &&
					filteredList.map((expert) => (
						<ExpertsGridItem key={expert.handle} {...{ expert }} />
					))}
			</ul>
		</Suspense>
	);
};

export default ExpertsGrid;
