// External
import React, { useContext, useEffect, useState } from 'react';

// Internal
import ProductSelectionLevelOne from './ProductSelectionLevelOne';
import Button from '../../../../../components/Button';
import ProductSelectionLevelTwo from './ProductSelectionLevelTwo';
import ProductMiracleActions from './ProductMiracleActions';

import {
	getMetafield,
	isAvailable,
	mergeArrays,
} from '../../../../../utils/helpers';
import { useWindowWidth } from '../../../../../utils/hooks';
import { StoreContext } from '../../../../../context/store-context';
import ProductRecommended from '../../../../products/ProductFirstScreen/ProductRecommended';
import DeliveryRadioV2 from '../../../../../components/DeliveryRadioV2';

const ProductSelectionLevels = ({
	levelNumber,
	product,
	canSubscribe,
	activeRadio,
	setActiveRadio,
	sellingPlanGroup,
	selectedValue,
	setSelectedValue,
	options,
	discountPrice,
	recommendedProductHandle,
	recommendedTag,
	recommendedText,
	recommendedTextMobile,
	recommendedProduct,
	setRecommended,
	isRecommendedSelected,
	setRecommendedSelected,
	activeFirstLevel,
	setActiveFirstLevel,
	setProductImage = undefined,
	isUpsell,
}) => {
	const { setSlideProperties, allProducts } = useContext(StoreContext);
	const windowWidth = useWindowWidth();
	const isOneLevel = levelNumber === 1;
	const availableForSale = isAvailable(product);
	const isFilterActive = getMetafield(product, 'filter_active');
	const usesShadeShotSelection = getMetafield(
		product,
		'uses_select_shade_shot_section'
	);
	const skipAddingMiracleShotAutomatically = getMetafield(
		product,
		'skip_adding_the_miracle_shot_automatically'
	);
	const fieldData =
		getMetafield(product, 'selection_first_level_list') &&
		getMetafield(product, 'selection_first_level_list').split('|');

	const getFirstLevelData = () => {
		if (!isOneLevel) {
			const firstLevelData = allProducts
				.filter((item) => fieldData.includes(item.handle))
				.sort(
					(a, b) =>
						parseFloat(
							a.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
						).toFixed(1) -
						parseFloat(
							b.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
						).toFixed(1)
				);
			return firstLevelData;
		}
		return product.variants.filter(
			(item) => !item.title.toLowerCase().includes('miracle shot')
		);
	};

	const getFiltersArrays = (data) =>
		data &&
		data.map(
			(item) =>
				getMetafield(item, 'filter_type') &&
				getMetafield(item, 'filter_type').split('|')
		);
	const getFiltersList = (arrays) => mergeArrays(arrays);

	const [firstLevelData, setFirstLevelData] = useState(
		allProducts && getFirstLevelData()
	);
	const [filtersArrays, setFiltersArrays] = useState(
		firstLevelData && getFiltersArrays(firstLevelData)
	);
	const [filtersList, setFiltersList] = useState(
		filtersArrays && getFiltersList(filtersArrays)
	);
	const [activeSecondLevel, setActiveSecondLevel] = useState(null);
	const [secondLevelData, setSecondLevelData] = useState([]);
	const [errorSelectLevel, setErrorSelectLevel] = useState({
		errorLevelOne: '',
		errorLevelTwo: '',
		errorSelect: '',
	});

	const optionsObjects = options.map((opt, i) => ({
		value: i,
		label: `Every ${opt} ${opt === '4 Weeks' ? '(most common)' : ''}`,
	}));

	const levelValidator = {
		isValidOne: activeFirstLevel,
		isValidTwo: activeSecondLevel,
	};

	useEffect(() => {
		setFirstLevelData(getFirstLevelData());
	}, [allProducts]);

	useEffect(() => {
		setFiltersArrays(getFiltersArrays(firstLevelData));
	}, [firstLevelData]);

	useEffect(() => {
		setFiltersList(getFiltersList(filtersArrays));
	}, [filtersArrays]);

	useEffect(() => {
		const currentVariants =
			activeFirstLevel &&
			getMetafield(activeFirstLevel, 'selection_second_level_list') &&
			getMetafield(activeFirstLevel, 'selection_second_level_list')
				.split('|')
				.map((item) => item.split(':')[1]);

		if (currentVariants && usesShadeShotSelection) {
			const activeSecondLevel = product.variants.filter(
				(item) =>
					item.storefrontId &&
					currentVariants.includes(
						item.storefrontId
							.replace('gid://shopify/Product/', '')
							.replace('gid://shopify/ProductVariant/', '')
					)
			);
			setSecondLevelData(activeSecondLevel);
		}
		setSlideProperties(activeFirstLevel);
	}, [activeFirstLevel]);

	useEffect(() => {
		const variantSecondLevel =
			!usesShadeShotSelection && activeFirstLevel
				? product.variants.find((variant) =>
						activeFirstLevel.title.includes(variant.title)
				  )
				: null;
		const variantSelectImage = variantSecondLevel?.metafields?.find(
			(metafield) => metafield.key === 'selected_variant'
		)?.value;

		if (variantSelectImage && !usesShadeShotSelection) {
			const productWithVariantSelectImage = {
				...variantSecondLevel,
				variantSelectImage,
			};
			setSlideProperties(productWithVariantSelectImage);
		}

		setActiveSecondLevel(variantSecondLevel);
		if (!usesShadeShotSelection && !variantSelectImage) {
			setSlideProperties(variantSecondLevel);
			if (setProductImage) {
				setProductImage(
					usesShadeShotSelection
						? activeSecondLevel.image.gatsbyImageData
						: activeFirstLevel.image.gatsbyImageData
				);
			}
		}
	}, [activeFirstLevel]);

	useEffect(() => {
		if (activeSecondLevel) {
			const variantSelectImage = activeSecondLevel?.metafields?.find(
				(metafield) => metafield.key === 'selected_variant'
			)?.value;
			const productWithVariantSelectImage = {
				...activeSecondLevel,
				variantSelectImage,
			};
			setSlideProperties(
				usesShadeShotSelection
					? productWithVariantSelectImage
					: activeFirstLevel
			);
			if (setProductImage) {
				setProductImage(
					usesShadeShotSelection
						? activeSecondLevel.image.gatsbyImageData
						: activeFirstLevel.image.gatsbyImageData
				);
			}
		}
	}, [activeSecondLevel]);

	return (
		<div className={`pdp-selection ${isUpsell && 'pdp-selection-upsell'}`}>
			<div className='pdp-selection__desktop'>
				<ProductSelectionLevelOne
					isFilterActive={isFilterActive}
					isOneLevel={isOneLevel}
					items={firstLevelData}
					filtersList={filtersList}
					activeFirstLevel={activeFirstLevel}
					setActiveFirstLevel={setActiveFirstLevel}
					errorSelectLevel={errorSelectLevel}
				/>
				{!isOneLevel && usesShadeShotSelection && (
					<ProductSelectionLevelTwo
						isFilterActive={isFilterActive}
						items={secondLevelData}
						activeFirstLevel={activeFirstLevel}
						setActiveSecondLevel={setActiveSecondLevel}
						activeSecondLevel={activeSecondLevel}
						isOneLevel={isOneLevel}
						errorSelectLevel={errorSelectLevel}
						skipAddingMiracleShotAutomatically={
							skipAddingMiracleShotAutomatically
						}
					/>
				)}
				{isOneLevel ? (
					<>
						{recommendedProductHandle && windowWidth >= 991 && (
							<ProductRecommended
								product={product}
								recommendedProductHandle={
									recommendedProductHandle
								}
								recommendedTag={recommendedTag}
								recommendedText={recommendedText}
								recommendedTextMobile={recommendedTextMobile}
								setRecommended={setRecommended}
								isRecommendedSelected={isRecommendedSelected}
								setRecommendedSelected={setRecommendedSelected}
							/>
						)}
						<Button
							product={product}
							disabled={!activeFirstLevel}
							isActiveClass={activeFirstLevel}
							isRecommendedSelected={isRecommendedSelected}
							recommendedProduct={recommendedProduct}
							isBundlePdp
							variantId={
								activeFirstLevel &&
								activeFirstLevel.storefrontId
							}
							availableForSale={availableForSale}
						/>
					</>
				) : (
					<>
						{!!canSubscribe && (
							<DeliveryRadioV2
								activeRadio={activeRadio}
								setActiveRadio={setActiveRadio}
								classname='pdp-miracle-radios'
								product={product}
								levelValidator={levelValidator}
								setErrorSelectLevel={setErrorSelectLevel}
								optionsObjects={optionsObjects}
								selectedValue={selectedValue}
								setSelectedValue={setSelectedValue}
								handleSelect={null}
								origin='desktop'
								usesShadeShotSelection={usesShadeShotSelection}
							/>
						)}
						<div className='flex align-items'>
							<ProductMiracleActions
								{...{
									product,
									canSubscribe,
									discountPrice,
									optionsObjects,
									selectedValue,
									setSelectedValue,
									sellingPlanGroup,
									activeRadio,
									activeSecondLevel,
									recommendedProduct,
									isRecommendedSelected,
									levelValidator,
									setErrorSelectLevel,
									usesShadeShotSelection,
								}}
								origin='desktop'
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default ProductSelectionLevels;

