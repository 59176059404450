const mainFiltersCollections = [
	'hair care',
	'permanent colour',
	'root touch-up',
	'gloss',
];

const filters = {
	articles: [
		'colour',
		'covering roots',
		'grey stories',
		'haircare',
		'trend',
		'hair styling',
	],
	collections: {
		'all-products': mainFiltersCollections,
		'best-selling-products': mainFiltersCollections,
		'permanent-hair-colour': ['hair care', 'permanent colour'],
		'semi-permanent-hair-colour': mainFiltersCollections,
		'hair-gloss-and-glaze': ['hair care', 'permanent colour', 'gloss'],
		'root-touch-up': mainFiltersCollections,
		'colour-correctors': ['hair care', 'permanent colour', 'gloss'],
		'hair-care': ['hair care', 'permanent colour'],
		'permanent-hair-colour-reduces-breakage-miracle-range': [
			'hair care',
			'permanent colour',
		],
		'hair-colouring-accessories': mainFiltersCollections,
		'gift-sets': mainFiltersCollections,
		'gloss-and-glaze': ['light hair', 'dark hair', 'red hair'],
		colour: ['light hair', 'dark hair'],
		'root-touch-up': ['light hair', 'dark hair', 'grey coverage'],
		gloss: ['light hair', 'dark hair', 'brights', 'naturals'],
		care: ['light hair', 'dark hair'],
		accessories: ['light hair', 'dark hair', 'grey coverage'],
		'root-smudger': ['dark hair', 'grey coverage'],
		shampoos: ['light hair', 'dark hair'],
		conditioners: ['light hair', 'dark hair'],
		'root-touch-up-brushes': ['light hair', 'dark hair', 'grey coverage'],
		'root-marker-pens': ['light hair', 'dark hair', 'grey coverage'],
		red: [
			'light hair',
			'dark hair',
			'grey coverage',
			'brights',
			'naturals',
		],
		'grey-coverage': ['light hair', 'dark hair', 'grey coverage'],
		'shade-shot': ['light hair', 'dark hair'],
	},
};

export default filters;
