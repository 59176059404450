import React, { useContext, useEffect } from 'react';

import FilterCheck from './FilterCheck';

import { StoreContext } from '../../context/store-context';

import './Filter.scss';

const Filter = ({
	list,
	type,
	query,
	blogFilters = [],
	collectionFilters = [],
	collectionSorts = { name: [] },
	isExperts = false,
}) => {
	const {
		filterTags,
		sortKeys,
		filterList,
		updateFilterTag,
		updateSortKey,
		resetFilters,
		resetSorts,
		setFilterList,
	} = useContext(StoreContext);
	useEffect(() => {
		if (!blogFilters.length && !collectionFilters.length) {
			resetFilters();

			list.forEach((i) => {
				const tags = i.node ? i.node.tags : i.tags;
				// setFilterList(tags);
			});
		}
		return () => {
			resetFilters();
			resetSorts();
		};
	}, []);

	const handleFilter = (tag) => {
		updateFilterTag(tag);
	};
	const handleSort = (key) => {
		updateSortKey(key);
	};
	const filters = collectionFilters.length
		? collectionFilters
		: query
		? (blogFilters.length ? blogFilters : filterList).filter(
				(f) => f === query.replace('-', ' ')
		  )
		: blogFilters.length
		? blogFilters
		: filterList;

	let items = isExperts ? list.map((item) => item.tags[0]) : filters;
	items = items.filter(
		(item) =>
			!(
				item.includes('yblocklist') ||
				item.includes('klaviyo') ||
				item.includes('ygroup')
			)
	);

	return (
		<div
			className={`align-items filter-wrap ${
				!type && 'filter-wrap--margin'
			}`}
		>
			{collectionSorts.names?.length && (
				<ul className={`${type ? `filter-${type}` : 'filter'}`}>
					{(collectionSorts.names || []).map((sortName, index) => (
						<li
							key={sortName}
							/* eslint-disable-next-line */
							role='button'
							className={`${
								type ? `filter-${type}__item` : 'filter__item'
							} ${
								(sortKeys || []).includes(
									collectionSorts.keys[index]
								) &&
								(!type
									? 'filter__item--active'
									: `filter-${type}__item--active`)
							}`}
							onKeyPress={() =>
								handleSort(collectionSorts.keys[index])
							}
							onClick={() =>
								handleSort(collectionSorts.keys[index])
							}
						>
							{!type && <FilterCheck />}
							<span className={!type ? 'filter-item__name' : ''}>
								{sortName}
							</span>
						</li>
					))}
				</ul>
			)}
			<ul className={`${type ? `filter-${type}` : 'filter'}`}>
				{(items || []).map((filter) => (
					<li
						key={filter}
						className={`${
							type ? `filter-${type}__item` : 'filter__item'
						} ${
							(filterTags || []).includes(filter) &&
							(!type
								? 'filter__item--active' // eslint-disable-line
								: `filter-${type}__item--active`) // eslint-disable-line
						}`}
						/* eslint-disable-next-line */
						role='button'
						onKeyPress={() => handleFilter(filter)}
						onClick={() => handleFilter(filter)}
					>
						{!type && <FilterCheck />}
						<span className={!type ? 'filter-item__name' : ''}>
							{filter}
						</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Filter;
