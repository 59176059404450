// External
import React, { useContext, useEffect, Suspense, useState } from 'react';
import { graphql } from 'gatsby';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import axios from 'axios';

// Internal
import SEO from '../components/SEO';
import Homepage from '../templates/Homepage';
import { StoreContext } from '../context/store-context';
import { getSellingPlanGroups, isBrowser } from '../utils/helpers';
import applyDiscount from '../utils/cart/applyDiscount';
import usePreviewableStoryblokState from '../utils/usePreviewableStoryblokState';
import { codesToPreAdd, seo } from '../constants/home-page';
import { applyPreAddDiscount } from '../helpers/homepage';
import Loader from '../components/Loader';
import HomePageV2 from './pages/home-v2';

const IndexPage = ({ data, location, code }) => {
	const { cart, addVariantToCart, sbClient, yotpoRatings, globalMetafields } = useContext(StoreContext);

	const [allSellingPlanGroups, setAllSellingPlanGroups] = useState(null);
	const page = seo['/'];
	const { story } = usePreviewableStoryblokState(
		sbClient,
		data.storyblokEntry
	);

	const promo =
		story.content?.body?.find(
			(content) => content.component === 'Promo Hero'
		) || null;
	const productsCustomerLove = globalMetafields?.find(
		(item) => item.key === 'products_customer_love'
	)?.value || '';

	const combineCollections = globalMetafields?.find(
		(item) => item.key === 'combine_collections'
	)?.value || '';

	const ratingMiracle = yotpoRatings?.find(
		(item) => item.domain_key === '6568869068880'
	) || {};

	/* const components = story.content.body.map((blok) => (
		<Suspense fallback={<Loader />} key={blok._uid}>
			<StoryblokComponent blok={blok} />
		</Suspense>
	)); */

	useEffect(() => {
		const fetchSellingPlanGroups = async () => {
			const response = await axios.get('/api/getSellingPlanGroups', {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (!response.data) {
				throw new Error('API request failed');
			}

			const { data } = response.data;
			setAllSellingPlanGroups(data);
		};
		fetchSellingPlanGroups();
	}, []);
	useEffect(() => {
		const handleDiscount = async () => {
			const cartId = isBrowser && localStorage.getItem('shopify_cart_id');
			const matchingDiscountCode = codesToPreAdd.find(
				(item) => item.code === code
			);

			if (matchingDiscountCode) {
				await applyPreAddDiscount(cartId, code, addVariantToCart);
			} else {
				await applyDiscount(cartId, code);
			}

			if (isBrowser) {
				window.history.pushState(null, null, '/');
				window.location.reload();
			}
		};
		if (code && cart) {
			handleDiscount();
		}
	}, [code, cart]);
	return (
		<>
			<SEO page={page} location={location} />
			{/* <Homepage components={components} /> */}
			<HomePageV2
				pageContextProps={{
					promo,
					productIdReviews: '6568869068880',
					productsCustomerLove,
					combineCollections,
					ratingMiracle,
					allSellingPlanGroups,
				}}
			/>
		</>
	);
};

export const query = graphql`
	{
		storyblokEntry(full_slug: { eq: "home" }) {
			content
			name
			full_slug
			uuid
			id
			internalId
		}
	}
`;

export default IndexPage;
