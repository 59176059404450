// External
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

const ProductPhotoGallery = ({
	product,
	thumbsSwiper,
	setThumbsSwiper,
	customPreviewImage,
}) => {
	const countThumb = (product?.media || []).length;
	const sliderGallerySettings = {
		loop: false,
		slidesPerView: 5,
		spaceBetween: 10,
		onSwiper: setThumbsSwiper,
		breakpoints: {
			1200: {
				spaceBetween: 12,
			},
		},
		preloadImages: false,
		lazy: true,
	};
	const goNext = () => {
		if (thumbsSwiper !== null) {
			thumbsSwiper.slideNext();
		}
	};
	const goPrev = () => {
		if (thumbsSwiper !== null) {
			thumbsSwiper.slidePrev();
		}
	};

	return (
		<nav className='pdp-photo-gallery' aria-label='Product photo gallery'>
			{countThumb > 5 && (
				<button
					type='button'
					className='pdp-prev-arrow'
					onClick={goPrev}
					aria-label='Previous photos'
				/>
			)}
			<Swiper {...sliderGallerySettings}>
				{customPreviewImage && (
					<SwiperSlide
						className='pdp-photo-gallery__slide'
						key={customPreviewImage}
					>
						<figure className='pdp-photo-gallery__item'>
							<LazyLoadImage
								src={customPreviewImage}
								alt={product?.title}
								className='pdp-photo-gallery__img swiper-lazy'
								effect='blur'
								loading='eager'
							/>
						</figure>
					</SwiperSlide>
				)}
				{Array.isArray(product?.media) &&
					product.media.map((image, index) => (
						<SwiperSlide
							className='pdp-photo-gallery__slide'
							key={image.shopifyId}
						>
							{image && (
								<figure className='pdp-photo-gallery__item'>
									{image?.preview?.image?.gatsbyImageData &&
									!image?.preview.image.originalSrc?.includes(
										'.gif'
									) ? (
										<GatsbyImage
											className='swiper-lazy'
											alt={`${product.title} thumbnail`}
											image={
												image.preview.image
													.gatsbyImageData
											}
											loading={
												index === 0 ? 'eager' : 'lazy'
											}
										/>
									) : (
										<LazyLoadImage
											className='swiper-lazy'
											src={
												image.preview.image.originalSrc
											}
											alt={`${product.title} thumbnail`}
											effect='blur'
											loading='lazy'
										/>
									)}
								</figure>
							)}
						</SwiperSlide>
					))}
			</Swiper>
			{countThumb > 5 && (
				<button
					type='button'
					className='pdp-next-arrow'
					onClick={goNext}
					aria-label='Next photos'
				/>
			)}
		</nav>
	);
};

export default ProductPhotoGallery;
