const getRechargePlans = async (page) => {
	const response = await fetch(
		`https://api.rechargeapps.com/plans?limit=250&page=${page}&type=subscription`,
		{
			method: 'GET',
			headers: {
				'X-Recharge-Version': '2021-11',
				'X-Recharge-Access-Token':
					process.env.RECHARGE_CUSTOMERS_ACCESS_TOKEN,
			},
		}
	);
	const data = await response.json();
	if (data.plans.length > 0) {
		return data.plans;
	}
	return null;
};

module.exports.getSellingPlanGroupsIds = async () => {
	let sellingPlanGroupsIds = [];
	let allPlans = [];
	try {
		let page = 1;
		let hasMoreStories = true;
		/* eslint-disable no-await-in-loop */
		while (hasMoreStories) {
			const plans = await getRechargePlans(page);
			if (!plans || plans.length === 0) {
				hasMoreStories = false;
			} else {
				allPlans = allPlans.concat(plans);
				page++;
			}
		}

		if (Array.isArray(allPlans)) {
			allPlans.forEach(({ external_plan_group_id }) => {
				if (
					external_plan_group_id &&
					!sellingPlanGroupsIds.includes(
						`gid://shopify/SellingPlanGroup/${external_plan_group_id}`
					)
				) {
					sellingPlanGroupsIds = [
						...sellingPlanGroupsIds,
						`gid://shopify/SellingPlanGroup/${external_plan_group_id}`,
					];
				}
			});
		}

		return sellingPlanGroupsIds;
	} catch (error) {
		console.error(error);
	}
};
