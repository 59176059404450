import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import icon from '../../../../static/images/info-icon.svg';
import './ProductWithVariants.scss';
import ProductVariantSelector from '../../../templates/products/ProductFirstScreen/ProductInfo/ProductVariantSelector';
import Button from '../../Button';

const ProductWithVariants = ({ product }) => {
	const [productImage, setProductImage] = useState(
		product?.media[0]?.preview.image.gatsbyImageData
	);
	const [productPrice, setProductPrice] = useState(
		product?.variants[0]?.price
	);
	const [selectedVariant, setSelectedVariant] = useState(
		product?.variants[0]
	);
	const [variantAvailableForSale, setVariantAvailableForSale] = useState(
		product?.variants[0].availableForSale
	);
	const [activeVariant, setActiveVariant] = useState(null);

	useEffect(() => {
		setProductImage(selectedVariant.image.gatsbyImageData);
		setProductPrice(selectedVariant.price);
		setVariantAvailableForSale(selectedVariant.availableForSale);
	}, [selectedVariant]);
	return (
		<section className='product-with-variants-upsell'>
			<section className='product-with-variants-upsell__image'>
				<GatsbyImage
					image={productImage}
					className='product-with-variants-upsell__image-img'
				/>

				<Link to='/products/root-smudge'>
					<img
						src={icon}
						className='product-with-variants-upsell__image-icon'
						alt='text'
					/>
					<p>more info</p>
				</Link>
			</section>
			<section className='product-with-variants-upsell__content'>
				<section className='product-with-variants-upsell__content--title'>
					<h5>{product?.title}</h5>
					<p>£{productPrice}</p>
				</section>
				<section className='product-with-variants-upsell__content--description'>
					<p>
						The Root Smudge offers a brand new temporary solution to
						covering greys and extending hair...
					</p>
				</section>
				<ProductVariantSelector
					product={product}
					selectedVariant={selectedVariant}
					setSelectedVariant={setSelectedVariant}
					setActiveVariant={setActiveVariant}
					isPermamentHairColour={false}
					isUpsell
				/>
				<Button
					product={product}
					isDark
					isPdp
					availableForSale={variantAvailableForSale}
					isVariant
					variantId={selectedVariant.storefrontId}
				/>
			</section>
		</section>
	);
};

export default ProductWithVariants;
