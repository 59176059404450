import React from 'react';
import { Link } from 'gatsby';
import 'react-lazy-load-image-component/src/effects/blur.css';

import ProductOneTestimonial from '../../../templates/products-v2/ProductFirstScreenV2/ProductOneTestimonial';
import LocalImage from '../../LocalImage';
import YotpoV2 from '../../Yotpo/YoptoV2';

const rachaelReview = {
	reviewText:
		"I can't live without this one for my roots when they come through in-between hair colourings.",
	name: 'Rachel C.',
};

const GeneralInformation = ({
	reverse = false,
	contentType = '1',
	rating = null,
}) => {
	const contentTitle =
		contentType === '1' ? (
			<>
				Strengthen your hair with <br />
				<span>The Miracle System</span>
			</>
		) : (
			<>
				Keep greys your secret with our <br />
				<span>Root Touch Up range</span>
			</>
		);

	const haveTitle = contentType === '1' || contentType === '2';

	const imagePath = haveTitle
		? contentType === '1'
			? '/home-page/image1.webp'
			: '/home-page/before-root.png'
		: '/home-page/image3.webp';

	return (
		<div
			className={`pdp-testimonial__content homepage ${
				reverse && 'pdp-testimonial__content-reverse'
			}`}
		>
			<div className='pdp-testimonial__content__image'>
				<LocalImage
					image={imagePath}
					alt='Testimonial about the Miracle System'
				/>
			</div>
			<div className='pdp-testimonial__content__text'>
				{haveTitle ? (
					<div className='pdp-testimonial__content__text-title'>
						{contentTitle}
					</div>
				) : (
					<div className='pdp-testimonial__content__text-title'>
						Subscribe and Save!
					</div>
				)}

				{contentType === '1' && (
					<>
						<p className='pdp-testimonial__content__text-desc'>
							Designed to cover your greys, whilst treating and
							protecting your hair from damage. Unlock the best
							shade of you with the POWER OF 3:
						</p>

						<div className='about-the-miracle-system-v2__list'>
							<div className='about-the-miracle-system-v2__list__item'>
								<LocalImage
									image='/permanent-colour-2.png'
									alt='Kind Permanent Colour'
								/>
								<p className='about-the-miracle-system-v2__list__item__desc'>
									<strong>Kind Permanent Colour</strong>
									<br />
									100% grey coverage, no ammonia, and rich,
									natural-looking colour.
								</p>
							</div>
							<div className='about-the-miracle-system-v2__list__item'>
								<LocalImage
									image='/shade-shot-2.png'
									alt='Toning Shade Shot'
								/>
								<p className='about-the-miracle-system-v2__list__item__desc'>
									<strong>Toning Shade Shot</strong>
									<br />
									Glossy toner that creates personalised
									results to suit your skin and eyes.
								</p>
							</div>
							<div className='about-the-miracle-system-v2__list__item'>
								<LocalImage
									image='/miracle-shot.png'
									alt='Repairing Miracle Shot'
								/>
								<p className='about-the-miracle-system-v2__list__item__desc'>
									<strong>Repairing Miracle Shot</strong>
									<br />
									The secret sauce to softness, shine &
									strength.
								</p>
							</div>
						</div>

						<div className='pdp-testimonial__content__text-action-2'>
							<Link
								to='/products/miracle-system'
								className='pdp-testimonial__content__text__link'
							>
								Shop Miracle System
							</Link>

							<YotpoV2 rating={rating} showCount isHomepage />
						</div>
					</>
				)}

				{contentType === '2' && (
					<>
						<p className='pdp-testimonial__content__text-desc'>
							Make your colour last for as long as you need by
							blending, spraying or smudging greys away. Ideal for
							at-home and salon coloured hair.
						</p>
						<Link
							to='/collections/root-touch-up'
							className='pdp-testimonial__content__text__link'
						>
							Shop Root Touch-Up
						</Link>
						<ProductOneTestimonial
							isHomePage
							review={rachaelReview}
						/>
					</>
				)}

				{contentType === '3' && (
					<>
						<div className='pdp-testimonial__content__text-action'>
							<ul className='salon-block__list no-margin'>
								<li className='salon-block__item'>
									FREE gifts
								</li>
								<li className='salon-block__item'>
									FREE shipping
								</li>
								<li className='salon-block__item'>
									EXCLUSIVE discounts
								</li>
								<li className='salon-block__item'>
									NO COMMITMENT
								</li>
							</ul>

							<div className='pdp-testimonial__content__text-action__item'>
								<Link
									to='/miracle-steps'
									className='pdp-testimonial__content__text__link'
								>
									Find My Shade
								</Link>
								<Link
									to='/pages/miracle-about'
									className='pdp-testimonial__content__text__link outline-border'
								>
									Find Out More
								</Link>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default GeneralInformation;
