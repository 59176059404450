import React, { useContext, useState } from 'react';
import { StoreContext } from '../../context/store-context';
import MiracleSystem from './MiracleSystem';
import NormalProduct from './NormalProduct';
import ProductWithVariants from './ProductWithVariants';

import './UpsellPDP.scss';

const PRODUCT_IDS = {
	MIRACLE_SYSTEM: 6568869068880,
	ROOT_SMUDGE: 6919156891728,
	MIRACLE_MASK: 4513722925136,
	COMB: 4818347524176,
};

const UPSELL_CATEGORIES = {
	COLOUR: 'Colour',
	ROOT_COVER: 'Root Cover',
	CONDITION: 'Condition',
	TOOLS: 'Tools',
};

const UpsellPDP = ({ sellingPlanGroup }) => {
	const [categorySelected, setCategorySelected] = useState(
		UPSELL_CATEGORIES.COLOUR
	);
	const { allProducts } = useContext(StoreContext);

	const productMiracleSystem = allProducts?.find((product) =>
		product.storefrontId.includes(PRODUCT_IDS.MIRACLE_SYSTEM)
	);
	const productRootSmudge = allProducts?.find((product) =>
		product.storefrontId.includes(PRODUCT_IDS.ROOT_SMUDGE)
	);
	const productMiracleMask = allProducts?.find((product) =>
		product.storefrontId.includes(PRODUCT_IDS.MIRACLE_MASK)
	);
	const productComb = allProducts?.find((product) =>
		product.storefrontId.includes(PRODUCT_IDS.COMB)
	);

	const handleSelectCategory = (category) => {
		setCategorySelected(category);
	};

	return (
		<section className='upsell-pdp'>
			<h4 className='upsell-pdp__title'>Complete your look</h4>
			<section className='upsell-pdp__select'>
				<ul>
					<li>
						<button
							onClick={() =>
								handleSelectCategory(UPSELL_CATEGORIES.COLOUR)
							}
						>
							<div
								className={`option ${
									categorySelected ===
										UPSELL_CATEGORIES.COLOUR &&
									'option-clicked'
								}`}
							>
								{categorySelected ===
									UPSELL_CATEGORIES.COLOUR && <div />}
							</div>
							Colour
						</button>
					</li>
					<li>
						<button
							onClick={() =>
								handleSelectCategory(
									UPSELL_CATEGORIES.ROOT_COVER
								)
							}
						>
							<div
								className={`option ${
									categorySelected ===
										UPSELL_CATEGORIES.ROOT_COVER &&
									'option-clicked'
								}`}
							>
								{categorySelected ===
									UPSELL_CATEGORIES.ROOT_COVER && <div />}
							</div>
							Root Cover
						</button>
					</li>
					<li>
						<button
							onClick={() =>
								handleSelectCategory(
									UPSELL_CATEGORIES.CONDITION
								)
							}
						>
							<div
								className={`option ${
									categorySelected ===
										UPSELL_CATEGORIES.CONDITION &&
									'option-clicked'
								}`}
							>
								{categorySelected ===
									UPSELL_CATEGORIES.CONDITION && <div />}
							</div>
							Condition
						</button>
					</li>
					<li>
						<button
							onClick={() =>
								handleSelectCategory(UPSELL_CATEGORIES.TOOLS)
							}
						>
							<div
								className={`option ${
									categorySelected ===
										UPSELL_CATEGORIES.TOOLS &&
									'option-clicked'
								}`}
							>
								{categorySelected ===
									UPSELL_CATEGORIES.TOOLS && <div />}
							</div>
							Tools
						</button>
					</li>
				</ul>
			</section>
			<section className='upsell-pdp__product'>
				{categorySelected === UPSELL_CATEGORIES.COLOUR && (
					<MiracleSystem
						product={productMiracleSystem}
						sellingPlanGroup={sellingPlanGroup}
					/>
				)}
				{categorySelected === UPSELL_CATEGORIES.ROOT_COVER && (
					<ProductWithVariants product={productRootSmudge} />
				)}
				{categorySelected === UPSELL_CATEGORIES.CONDITION && (
					<NormalProduct
						product={productMiracleMask}
						description='Love your hair with this five-minute multi-tasking miracle hair colour mask'
						href='/products/miracle-mask'
					/>
				)}
				{categorySelected === UPSELL_CATEGORIES.TOOLS && (
					<NormalProduct
						product={productComb}
						description='This durable, high quality wood comb is essential when washing, colouring or styling hair.'
						href='/products/comb'
					/>
				)}
			</section>
		</section>
	);
};

export default UpsellPDP;
