import React, { useState, useRef } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import LocalImage from '../../../components/LocalImage';
import { useWindowWidth } from '../../../utils/hooks';
import reviewsMiracle from '../../../constants/reviewsMiracle';

import 'swiper/swiper-bundle.min.css';
import './ProductTestimonials.scss';

SwiperCore.use([Navigation]);

const ProductTestimonials = ({ reviews = reviewsMiracle }) => {
	const windowWidth = useWindowWidth();
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [thumbMobile, setThumbMobile] = useState(null);
	const [activeIndexTestimonial, setActiveIndexTestimonial] = useState(0);
	const swiperRef = useRef(null);

	const isMobile = windowWidth < 1023;

	const sliderTestimonialSettings = {
		loop: false,
		onSwiper: setThumbsSwiper,
		spaceBetween: 10,
		breakpoints: {
			320: {
				initialSlide: 0,
				slidesPerView: 1.5,
				virtualTranslate: false,
			},
			1200: {
				initialSlide: 0,
				slidesPerView: 1.5,
			},
			750: {
				initialSlide: 0,
				slidesPerView: 1.5,
			},
		},
		preloadImages: false,
		lazy: true,
		dir: 'rtl',
		virtualTranslate: true,
		allowTouchMove: false,
		preventClicks: false,
		preventClicksPropagation: false,
		onSlideNextTransitionStart: (swiper) => {
			const { wrapperEl } = swiper;
			const numberOfTranslate3D = isMobile ? 220 : 300;
			const translate3DImages =
				numberOfTranslate3D *
				(activeIndexTestimonial + (isMobile ? 1 : 0));
			wrapperEl.style.transform = `translate3d(${translate3DImages}px, 0, 0)`;
		},
		onSlidePrevTransitionStart: (swiper) => {
			const { wrapperEl, isBeginning } = swiper;
			const numberOfTranslate3D = isMobile ? 220 : 300;
			const currentTranslate3D = wrapperEl.style.transform;
			const valueCurrent = parseInt(
				currentTranslate3D.split('(')[1].split('px')[0]
			);
			let translate3DImages = valueCurrent - numberOfTranslate3D;
			if (isBeginning) {
				translate3DImages = 0;
			}
			wrapperEl.style.transform = `translate3d(${translate3DImages}px, 0, 0)`;
		},
	};

	const goNext = () => {
		if (thumbsSwiper !== null) {
			thumbsSwiper.slideNext();
			setActiveIndexTestimonial(thumbsSwiper.activeIndex);
		}
	};

	const goPrev = () => {
		if (thumbsSwiper !== null) {
			thumbsSwiper.slidePrev();
			setActiveIndexTestimonial(thumbsSwiper.activeIndex);
		}
	};

	const goNextMobile = () => {
		if (thumbMobile !== null) {
			thumbMobile.slideNext();
			setActiveIndexTestimonial(thumbMobile.activeIndex);
		}
	};

	const goPrevMobile = () => {
		if (thumbMobile !== null) {
			thumbMobile.slidePrev();
			setActiveIndexTestimonial(thumbMobile.activeIndex);
		}
	};

	return (
		<div className='pdp-testimonials'>
			<div className='pdp-testimonials__images-swiper' id='images-swiper'>
				{isMobile ? (
					<Swiper
						breakpoints={{
							320: {
								spaceBetween: 0,
								slidesPerView: 1.5,
								centeredSlides: true,
								initialSlide: Math.floor(
									(reviews?.length ?? 0) / 2
								),
							},
						}}
						navigation={{
							prevEl: '#custom-prev',
							nextEl: '#custom-next',
						}}
						onSwiper={(swiper) => setThumbMobile(swiper)}
						onSlideChange={(swiper) =>
							setActiveIndexTestimonial(swiper.activeIndex)
						}
					>
						{reviews?.map((src, index) => (
							<SwiperSlide
								key={`img-${src.name}-${index + 1}`}
								className='pdp-testimonials__images-swiper__slide-v2'
							>
								<LocalImage
									image={src.imgUrl}
									alt={`Customer ${index}`}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				) : (
					<Swiper {...sliderTestimonialSettings} ref={swiperRef}>
						{reviews?.map((testimonial, index) => (
							<SwiperSlide
								className='pdp-testimonials__images-swiper__slide'
								key={`${testimonial.name}-${index + 1}`}
							>
								<LocalImage
									image={testimonial.imgUrl}
									alt={`review-${index}`}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				)}
			</div>

			<div className='pdp-testimonials__text'>
				<span>★★★★★</span>
				<h2>{reviews?.[activeIndexTestimonial]?.title || ''}</h2>

				<p className='pdp-testimonials__text__desc'>
					{reviews?.[activeIndexTestimonial]?.text}
				</p>

				<p className='pdp-testimonials__text__author'>
					<b>{reviews?.[activeIndexTestimonial]?.name}</b>{' '}
					<span>Verified buyer</span>
				</p>

				<div className='pdp-testimonials__text__pagination'>
					<div
						className='pdp-testimonials__text__pagination__prev'
						onClick={!isMobile ? goNext : goPrevMobile}
					/>
					<div
						className='pdp-testimonials__text__pagination__next'
						onClick={!isMobile ? goPrev : goNextMobile}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductTestimonials;
