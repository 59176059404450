import React, { memo } from 'react';

import FooterTop from './FooterTop';
import FooterMiddle from './FooterMiddle';
import FooterBottom from './FooterBottom';
import InstaFeed from '../InstaFeed';
import FooterTopV2 from './FooterTopV2';

import './Footer.scss';

const Footer = ({ menu, location }) => (
	<>
		<InstaFeed location={location} />
		<footer className='footer'>
			{/* <FooterTop menu={menu} location={location} /> */}
			<FooterTopV2 menu={menu} location={location} />
			<FooterMiddle />
			<FooterBottom />
		</footer>
	</>
);

export default memo(Footer);
