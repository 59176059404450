export default [
	{
		title: 'The best system ever',
		name: 'Jennifer W.',
		text: 'I love the way this is delivered, packaged and how easy it is to mix. The scent is gorgeous and the barrier, stain wipe and gloves are all perfect. My hair has never felt glossier and I honestly get comments on my colour and I never have before! So silky, so shiny and long lasting',
		imgUrl: 'pdp/customers/woman-3.png',
	},
	{
		title: 'This is truly a MIRACLE!',
		name: 'Sarah E.',
		text: 'This system is awesome! My hair is as good as when I’ve been to a salon. I purchase on a subscription and I highly recommend to anyone. Covers my grey, feels in such good condition and so easy to use! Hurrah for Josh Wood. Saves me time and money 💰',
		imgUrl: 'pdp/customers/woman-5.png',
	},
	{
		title: 'Brilliant!',
		name: 'Gillian T.',
		text: 'Best at home hair colour I’ve ever used. Always left hair colouring to my hairdresser but changed my mind after reading the reviews and seeing people’s photos. The shade choices are spot on.  My shade is 6.0 with an icy shot.  I will definitely buy again.',
		imgUrl: 'pdp/customers/woman-2.png',
	},
	{
		title: 'Never disappoints',
		name: 'Alexandra N.',
		text: 'I am always satisfied with the Miracle System, the kit is easy to use every time and provides shine and super soft hair plus it covers all my white hairs 😁. The color is what I need and absolutely fabulous for me. Josh Wood never disappoints!! Thank you so much.',
		imgUrl: 'pdp/customers/woman-4.png',
	},
	{
		title: 'Thank you Josh Wood',
		name: 'Alexandra N.',
		text: "Absolutely love the miracle system, gives my hair a good grey coverage, shine, softness, is the best haircolor on the market. It is easy to find it and order it online. It looks very good with the package and all the instructions are very useful. It is very easy to do it yourself at home. Thank you Josh Wood for looking after everyone's hair.",
		imgUrl: 'pdp/customers/woman-1.png',
	},
];
