import React from 'react';

import FooterSubmenu from '../FooterTop/FooterSubmenu';
import FooterDropdown from '../FooterTop/FooterDropdown';

import { useWindowWidth } from '../../../utils/hooks';

const FooterList = ({ title, list, collectionsPreviews = [] }) => {
	const windowWidth = useWindowWidth();

	return (
		<div className='footer__col footer-v2__col'>
			{collectionsPreviews.length && windowWidth <= 750 ? (
				<FooterDropdown
					collectionsPreviews={collectionsPreviews}
					title={title}
					list={list}
				/>
			) : (
				<div>
					<h4>{title}</h4>
					<FooterSubmenu title={title} list={list} isV2 />
				</div>
			)}
		</div>
	);
};

export default FooterList;
