// External
import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import axios from 'axios';

// Internal
import { StoreContext } from '../../../context/store-context';

import AccountOrders from './AccountOrders';
import AccountDetails from './AccountDetails';
import useCustomer from '../../../utils/useCustomer';
import doDataLayerUserEvent from '../../../utils/dataLayer/dataLayerUserEvent';
import { decodeBase64 } from '../../../utils/helpers';
import { submitErrorToSlack } from '../../../utils/slackMessage';
import { setHrefLangTag, removeHrefLangTag } from '../../../helpers/setHrefLang';

const AccountPage = () => {
	const { cart, removeAuthorized, removeAllLineItems, tokenCustomer } =
		useContext(StoreContext);
	const [customerId, setCustomerId] = useState(null);
	const [orders, setOrders] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [rechargeLink, setRechargeLink] = useState(null);
	const { customerData, refetch } = useCustomer();

	useEffect(() => {
		if (customerData && customerData.customer) {
			setOrders(customerData.customer.orders.edges);
			setAddresses(customerData.customer.addresses.edges);
			setCustomerId(decodeBase64(customerData.customer.id));
		}
	}, [customerData]);

	const logout = async () => {
		const shopId = `${process.env.GATSBY_SHOP_ID}`;
		const alreadyLoggedIn = sessionStorage.getItem('logged_in');
		const isPasswordLessSession = sessionStorage.getItem('is_logged_in_password_less');
		if (alreadyLoggedIn) {
			sessionStorage.removeItem('logged_in');
		}

		if (isPasswordLessSession) {
			sessionStorage.removeItem('is_logged_in_password_less');

			const logoutRequestUrl = new URL(
				`https://shopify.com/authentication/${shopId}/logout`
			);

			logoutRequestUrl.searchParams.append('id_token_hint', sessionStorage.getItem('id_token'));
			logoutRequestUrl.searchParams.append('post_logout_redirect_uri', `https://joshwoodcolour.com`);

			window.location.href = logoutRequestUrl.toString();
			removeAllLineItems().then(() => removeAuthorized());
			return;
		}

		removeAllLineItems().then(() => removeAuthorized());
		navigate('/');
	};

	const getRechargeCustomerPortal = async () => {
		const token = window.sessionStorage.getItem('accessToken');
		const isPasswordLessSession = sessionStorage.getItem('is_logged_in_password_less');
		const paramsPasswordLess = isPasswordLessSession ? `&isPasswordLessSession=${isPasswordLessSession}` : '';
		const url = `/api/getRechargePortal?customerId=${customerId.replace(
			'gid://shopify/Customer/',
			''
		)}&token=${token}${paramsPasswordLess}`;
		try {
			const response = await axios.get(url);

			if (!response.data) {
				throw new Error('API request failed');
			}

			const data = response.data;
			setRechargeLink(data);
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack(url, error, 'GET');
			}
			console.error(error);
			return error;
		}
	};

	const checkNewLogin = () => {
		const alreadyLoggedIn = sessionStorage.getItem('logged_in');
		if (!alreadyLoggedIn) {
			sessionStorage.setItem('logged_in', 'true');
			doDataLayerUserEvent(customerId, cart, 'dl_login');
		}
	};

	useEffect(() => {
		if (customerData && customerData.customer) {
			setCustomerId(decodeBase64(customerData.customer.id));
		}
	}, [customerData]);

	useEffect(() => {
		if (customerId) {
			getRechargeCustomerPortal(customerId);
			checkNewLogin();
		}
	}, [customerId]);

	useEffect(() => {
		setHrefLangTag('account-page-hreflang');

		return () => {
			removeHrefLangTag('account-page-hreflang');
		};
	}, []);

	return (
		<div>
			<section className='account-page'>
				<div className='container'>
					<h1>My Account</h1>
					<button className='account-page__logout' onClick={logout}>
						Log out
					</button>
					<section className='account-page__subscriptions'>
						<h3>My Subscriptions</h3>
						{rechargeLink ? (
							<Link
								className='small-btn small-btn--margin-top subscription-button'
								to={rechargeLink}
							>
								Manage My Subscriptions
							</Link>
						) : (
							<p> No subscriptions </p>
						)}
					</section>
					{orders.length ? (
						<AccountOrders orders={orders} />
					) : (
						<div className='account-page__no-orders'>
							You have no orders yet
						</div>
					)}
					<div className='account-details'>
						<h3>Account Details</h3>
						{addresses.length ? (
							<>
								<AccountDetails
									item={addresses[0].node}
									isPreview
									customerId={customerId}
									refetch={refetch}
								/>
								{addresses.length > 1 ? (
									<Link
										className={`small-btn 
                                        small-btn--margin-top view-addresses`}
										to='/account/addresses'
									>
										View Addresses ({addresses.length})
									</Link>
								) : null}
							</>
						) : null}
					</div>
				</div>
			</section>
		</div>
	);
};

export default AccountPage;
