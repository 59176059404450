// External
import React, { useContext, useEffect, useState } from 'react';

// Internal
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StoreContext } from '../../../../context/store-context';
import ProductPermHairColour from './ProductPermHairColour';
import { useMetaImage } from '../../../../utils/hooks';
import { getMetafield } from '../../../../utils/helpers';

const ProductVariantSelector = ({
	product,
	selectedVariant,
	setSelectedVariant,
	setActiveVariant,
	isPermamentHairColour = false,
	isUpsell,
}) => {
	const { setSlideProperties } = useContext(StoreContext);
	const [colourNameSeparated, setColourNameSeparated] = useState([]);
	const [filterColour, setFilterColour] = useState('dark');

	const isRootSmudgeVariant = product.title
		.toLowerCase()
		.includes('root smudge');

	useEffect(() => {
		if (isRootSmudgeVariant) {
			const nameSeparated = selectedVariant.title.split(' ');

			if (nameSeparated.length > 2) {
				const nameSlice = nameSeparated.slice(0, -1).join(' ');
				const correctArray = [
					nameSlice,
					nameSeparated[nameSeparated.length - 1],
				];
				setColourNameSeparated(correctArray);
			} else {
				setColourNameSeparated(nameSeparated);
			}
		}
		setSlideProperties(selectedVariant);
	}, [selectedVariant]);
	const variantsHaveOrder = product.metafields?.find(
		(variant) => variant.key === 'variants_order'
	)?.value;

	const variantsSorted = variantsHaveOrder
		? product.variants.sort(
				(a, b) =>
					variantsHaveOrder.indexOf(a.storefrontId) -
					variantsHaveOrder.indexOf(b.storefrontId)
		  )
		: product.variants;

	return (
		<>
			<h5 className='variant__selector--informative'>
				Select colour:{' '}
				<>
					{!isPermamentHairColour && (
						<>
							{isRootSmudgeVariant ? (
								<span>
									{colourNameSeparated[0]} - {product.title}{' '}
									for colour {colourNameSeparated[1]}
								</span>
							) : (
								<span>{selectedVariant.title}</span>
							)}
						</>
					)}
				</>
			</h5>
			{isPermamentHairColour ? (
				<section className='perm-hair-colour'>
					<div className='select-colour-container'>
						<button
							onClick={() => {
								setFilterColour('dark');
								setSelectedVariant(
									product.variants.slice(0, 5)[0]
								);
							}}
							className={`${
								filterColour === 'dark' && 'button-active '
							}`}
						>
							Dark
						</button>
						<button
							onClick={() => {
								setFilterColour('light');
								setSelectedVariant(
									product.variants.slice(5)[0]
								);
							}}
							className={`${
								filterColour === 'light' && 'button-active '
							}`}
						>
							Light
						</button>
					</div>
					<div className='product-variant product-variant__less-margin'>
						<ProductPermHairColour
							product={
								filterColour === 'dark'
									? product.variants.slice(0, 5)
									: product.variants.slice(5)
							}
							allColours={product}
							setSelectedVariant={setSelectedVariant}
							setActiveVariant={setActiveVariant}
						/>
					</div>
					<span className='perm-hair-colour__title'>
						{' '}
						{selectedVariant.title}{' '}
					</span>
				</section>
			) : (
				<div className='product-variant'>
					{variantsSorted.map((variant) => {
						const productImageMetafield = getMetafield(
							variant,
							'image_variant'
						);
						let imageToShow;

						if (productImageMetafield) {
							const metafieldImage = useMetaImage(
								productImageMetafield
							);

							imageToShow = metafieldImage.metaImage;
						} else {
							imageToShow = getImage(variant.image);
						}

						return (
							<div
								key={variant.id}
								onClick={() => {
									setSelectedVariant(variant);
									setActiveVariant(variant);
								}}
								className={`target-variant__product ${
									isUpsell &&
									selectedVariant === variant &&
									'upsell-variant-selected'
								}`}
								data-id={variant.id}
							>
								{productImageMetafield ? (
									<LazyLoadImage
										src={imageToShow}
										alt='product variant'
										className='select-variant__product'
									/>
								) : (
									<GatsbyImage
										image={imageToShow}
										alt='product variant'
										className='select-variant__product'
									/>
								)}
							</div>
						);
					})}
				</div>
			)}
		</>
	);
};

export default ProductVariantSelector;
