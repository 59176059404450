import React, { useContext, useState } from 'react';
import cn from 'classnames';
import axios from 'axios';
// External
import { StoreContext } from '../../../context/store-context';
import { submitErrorToSlack } from '../../../utils/slackMessage';

const AccountDetails = ({
	item,
	isPreview = false,
	customerId = null,
	refetch,
}) => {
	const { setModal, showModal, token, tokenCustomer } = useContext(StoreContext);
	const [errors, setErrors] = useState([]);
	const deleteAddress = async (addressId) => {
		const isPasswordLessSession = JSON.parse(
			sessionStorage.getItem('is_logged_in_password_less') || 'false'
		);
		const graphql = JSON.stringify({
			query: `
				mutation customerAddressDelete(
				  $id: ID!, 
				  $customerAccessToken: String!, 
				) {
				  customerAddressDelete(
					id: $id
					customerAccessToken: $customerAccessToken
				  ) {
					customerUserErrors {
					  code
					  field
					  message
					}
					deletedCustomerAddressId
				  }
				}
			  `,
			variables: {
				id: addressId,
				customerAccessToken: isPasswordLessSession ? tokenCustomer : token,
			},
		});

		const url =
			`https://${process.env.SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`.replace(
				/\s+/g,
				''
			);

		const storeFrontAccess = isPasswordLessSession
			? process.env.GATSBY_STOREFRONT_ACCESS_TOKEN_CUSTOMER
			: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN;

		try {
			const response = await axios.post(url, graphql, {
				headers: {
					'X-Shopify-Storefront-Access-Token': storeFrontAccess,
					'Content-Type': 'application/json',
				},
			});

			if (!response.data) {
				throw new Error('API request failed');
			}

			const data = response.data;

			if (data.errors) {
				setErrors(data.errors.base);
			} else {
				refetch();
			}
		} catch (error) {
			const status = error?.response?.status;
			if (status >= 500) {
				submitErrorToSlack(url, error, 'POST');
			}
			console.error(error);
			return error;
		}
	};
	const openModal = () => {
		setModal(null, 'edit-customer-info', null, {
			customerId,
			refetch,
			item,
		});
		showModal(true);
	};

	return (
		<>
			<ul
				className={cn('account-details-list', {
					'account-details-list--centered': !isPreview,
				})}
				key={item.id}
			>
				{(item.firstName || item.lastName) && (
					<li className='account-details-list-item'>
						{item.firstName ? item.firstName : null}{' '}
						{item.lastName ? item.lastName : null}
					</li>
				)}
				{(item.address1 || item.address2) && (
					<li className='account-details-list-item'>
						{item.address1 ? item.address1 : null}{' '}
						{item.address2 ? item.address2 : null}
					</li>
				)}
				{item.city && (
					<li className='account-details-list-item'>{item.city}</li>
				)}
				{item.zip && (
					<li className='account-details-list-item'>{item.zip}</li>
				)}
				{item.country && (
					<li className='account-details-list-item'>
						{item.country}
					</li>
				)}
			</ul>
			<div
				className={cn(
					'account-details-list__buttons account-details-list-buttons', // eslint-disable-line
					{ 'account-details-list-buttons--left': isPreview }
				)}
			>
				<button
					onClick={openModal}
					className='account-details-list-buttons__item
                    account-details-list-buttons__item--edit
                    small-btn small-btn--margin-top'
				>
					Edit
				</button>
				<button
					className='account-details-list-buttons__item small-btn small-btn--margin-top'
					onClick={() => {
						deleteAddress(item.id);
					}}
				>
					Delete
				</button>
			</div>
			{Array.isArray(errors) &&
				errors.map((error) => (
					<div key={error} className='address-error'>
						{error}
					</div>
				))}
		</>
	);
};

export default AccountDetails;
