import React, { useContext } from 'react';

import SubmenuLinkItem from './SubmenuLinkItem';

import { StoreContext } from '../../../context/store-context';

const FooterList = ({ title, list, isV2 = false }) => {
	const { setModal, showModal } = useContext(StoreContext);
	const showContactModal = () => {
		setModal(null, 'contact us', null);
		showModal(true);
	};

	return (
		<ul className={`footer__list ${isV2 && 'footer-v2__list'}`}>
			{list?.map((item) => (
				<li key={item.id}>
					{!item.title.toLowerCase().includes('contact us') ? (
						<SubmenuLinkItem item={item} />
					) : (
						<a href='mailto:expert@joshwoodcolour.com'>
							<span>{item.title}</span>
						</a>
					)}
				</li>
			))}
		</ul>
	);
};

export default FooterList;
