import React from 'react';
import { Link } from 'gatsby';

const AccountOrdersItem = ({ order }) => {
	const id = order.id.substr(20, 13),
		date = new Date(order.processedAt).toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		}),
		totalPrice = order.totalPrice;

	return (
		<tr key={id} className='account-order-item'>
			<td data-label='Order'>
				<div className='text-alignment'>
					<Link
						to={order.statusUrl}
						state={order}
						aria-label={`Order number ${order.name}`}
						aria-describedby='a11y-external-message'
					>
						{order.name}
					</Link>
				</div>
			</td>
			<td data-label='Date'>
				<time dateTime={order.processedAt} className='text-alignment'>
					{date}
				</time>
			</td>
			<td className='account-orders__status' data-label='Payment Status'>
				<p className='text-alignment'>
					{order.financialStatus.replace('/', ' ')}
				</p>
			</td>
			<td
				className='account-orders__status'
				data-label='Fulfillment Status'
			>
				<p className='text-alignment'>
					{order?.fulfillmentStatus?.replace('_', ' ') || 'Unfulfilled'}
				</p>
			</td>
			<td data-label='Total'>
				<span className='money text-alignment'>£ {totalPrice.amount}</span>
			</td>
		</tr>
	);
};

export default AccountOrdersItem;
