import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { Configure, InstantSearch } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';

import './Search.scss';
import Search from './Search';
import SEO from '../../components/SEO';
import { StoreContext } from '../../context/store-context';

const SearchContainer = ({
	data,
	location,
	pageContext: { allSellingPlanGroups },
}) => {
	const { allProducts } = useContext(StoreContext);
	const bestSellersProducts = data?.shopifyCollection.products;

	const searchClient = algoliasearch(
		'5V6H94NGY1',
		'349e7c5c16d05e2a3c2f5bf5864c8324'
	);

	return (
		<div className='search-container-full'>
			<SEO location={location} />
			<InstantSearch
				searchClient={searchClient}
				indexName='shopify_products'
			>
				<Configure hitsPerPage={8} />
				<Search
					allProducts={allProducts}
					featuredProducts={bestSellersProducts}
					allSellingPlanGroups={allSellingPlanGroups}
				/>
			</InstantSearch>
		</div>
	);
};

export const query = graphql`
	{
		shopifyCollection(handle: { eq: "best-selling-products" }) {
			products {
				...ProductCard
			}
		}
	}
`;

export default SearchContainer;
