import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavLink from './NavLink';
import './MobileDropdown.scss';

const NavDropdown = ({ blok, isMenuOpened, setMenuOpen }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const {
		image,
		descriptor = '',
		title = '',
		children = null,
		indent = false,
	} = blok;

	if (dropdownOpen && !isMenuOpened) {
		setDropdownOpen(false);
	}

	let dropdownClasses = ['mobile-menu__dropdown-content'];
	let arrowClasses = ['mobile-menu__arrow'];

	if (dropdownOpen && isMenuOpened) {
		dropdownClasses = [
			...dropdownClasses,
			'mobile-menu__dropdown-content--show',
		];
		arrowClasses = [...arrowClasses, 'mobile-menu__arrow--open'];
	}

	return (
		<div
			className={`nav-padding mobile-menu__dropdown${
				dropdownOpen ? ' is-open' : ''
			}${indent ? ' menu--indent' : ''}`}
		>
			<button
				className='mobile-menu__button'
				onClick={() => setDropdownOpen(!dropdownOpen)}
			>
				{image && image.filename && (
					<LazyLoadImage
						src={image.filename}
						alt={title}
						className='mobile-menu__image'
					/>
				)}
				<div className='mobile-menu__text-box'>
					{descriptor !== '' && (
						<span className='mobile-menu__descriptor'>
							{descriptor}
						</span>
					)}
					<span className='mobile-menu__item-text'>{title}</span>
				</div>
				<LazyLoadImage
					src='/images/navigation/icons/down-arrow.png'
					alt='down-arrow'
					className={arrowClasses.join(' ')}
				/>
			</button>
			<div className={dropdownClasses.join(' ')}>
				<ul>
					{children &&
						children.length > 0 &&
						children.map((blok, index) => (
							<li
								key={`navDropdown${title}${blok._uid}${
									index + 1
								}`}
							>
								<NavLink
									blok={blok}
									setMenuOpen={setMenuOpen}
									isSubItem
								/>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};

export default NavDropdown;
